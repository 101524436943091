import { Blog } from '../components/portal/pagina/Blog';
import { BlogD } from '../components/portal/pagina/BlogD';
import { Contacto } from '../components/portal/pagina/Contacto';
import { Financiamiento } from '../components/portal/pagina/Financiamiento';
import { Nosotros } from '../components/portal/pagina/Nosotros';
import { Buro } from '../components/portal/pagina/Buro';
import {AvisoPrivacidad} from '../components/portal/pagina/AvisoPrivacidad';
import {TerminosCondiciones} from '../components/portal/pagina/TerminosCondiciones';
import {Faq} from '../components/portal/pagina/Faq';
import { DerechoArco } from '../components/portal/pagina/DerechoArco';
import { Contacto2 } from '../components/portal/pagina/Contacto2';
import { CatYComisiones } from '../components/portal/pagina/CatYComisiones';
import { Login } from '../components/portal/pagina/Login';
import {CreditoPersonal} from '../components/portal/pagina/CreditoPersonal'


const rutasPublicas = [
    { path: "/Blog", component: <Blog /> },
    { path: "/BlogD/:id", component: <BlogD /> },
    { path: "/Contacto", component: <Contacto /> },
    { path: "/Financiamiento", component: <Financiamiento /> },
    { path: "/Nosotros", component: <Nosotros /> },
    { path: "/Buro", component: <Buro /> },
    { path: "/Aviso-de-privacidad", component: <AvisoPrivacidad /> },
    { path: "/Terminos-y-condiciones",component:<TerminosCondiciones />},
    { path: "/Faq",component: <Faq />},
    { path: "/Derechos-arco",component:<DerechoArco />},
    { path: "/yyyy",component:<Contacto2 />},
    { path: "/ventas", component: <Login/>},
    { path: "/Credito-personal", component: <CreditoPersonal/>},
    { path: "/Cat-y-comisiones", component: <CatYComisiones/>}
  ];
  
  export default rutasPublicas;