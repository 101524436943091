import React,{useState,useEffect, useRef} from 'react'
import icon_percapita from '../../../img/icon-percapita.png';
import { Footer } from '../../front/Footer';
import { Navbar } from '../../front/Navbar';
import circulo_phone from '../../../img/circulo-phone.png';
import circulo_whats from '../../../img/circulo-whats.png';
import circulo_ubi from '../../../img/circulo-ubi.png';
import circulo_mail from '../../../img/circulo-mail.png';
import { useAnimation, useInView, motion } from 'framer-motion';
import { useFormik } from "formik";
import * as Yup from "yup";
import AlertMessage from "../../alert.component";
export const Contacto = () => {
    const[isLoading,setIsLoading] = useState(false);
    const [openMessage,setOpenMessage] = useState(false);
    const[message,setMessage] = useState("");
    const[severity,setSeverity] = useState("success");
    const { 
        values,
        errors,
        touched,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm
    
        }= useFormik({
        initialValues: {
          name: "",
          phone: "",
          email: "",
          city: "",
          state: "",
          message: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Tienes que ingresar la calle."),
            phone: Yup.string().required("Tienes que ingresar la calle."),
            email: Yup.string().required("Tienes que ingresar la calle."),
            city: Yup.string().required("Tienes que ingresar la calle."),
            state: Yup.string().required("Tienes que ingresar la calle."),
            message: Yup.string().required("Tienes que ingresar la calle."),
        }),
        onSubmit: values => {
            console.log(values)
             setIsLoading(true)
             const data = {
                name: values.name,
                email: values.email,
                phone:values.phone,
                city:values.city,
                state: values.state,
                message: values.message,
              }
            //   console.log(data)

              fetch('https://app.percapita.mx/api/user/sendData',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(data)
              })
              .then((res) => res.json())
              .then((data) => {
                  console.log(data)
                  if (data.error) {
                      setIsLoading(false);
                     setOpenMessage(true);
                     setMessage(data.error.message);
                     setSeverity("error");
                  }
                  setIsLoading(false);
                  setOpenMessage(true);
                  setMessage("Datos enviados correctamente, a la brevedad nos comunicaremos contígo");
                  resetForm();
              })
        }    
        })
        const handleClose = (event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpenMessage(false);
          };
          const ref = useRef(null);
    const ref2 = useRef(null);
    const inView = useInView(ref)
    const inView2 = useInView(ref2)
    const animation = useAnimation();
    const animation2 = useAnimation();
    const animation3 = useAnimation();
    const animation4 = useAnimation();
    useEffect(()=>{
        if(inView) {
            animation.start({
                y:0, 
                opacity:1, 
                scale: 1,
                transition:{ ease: "easeInOut", duration: 0.6, delay: 0.3 }
              })

              animation2.start({
                x:0, 
                opacity:1, 
                scale: 1,
                transition:{ ease: "easeInOut", duration: 0.6, delay: 0.3 }
              })
        }
        if(!inView){
            animation.start({
                y:'50%', 
                opacity:0, 
                scale: 0.5
              })
              animation2.start({
                x:'1%', 
                opacity:0, 
                scale: 0.5
              })
        }
    },[inView])

    useEffect(() => {
        document.title = "Percapita - Contacto"
    },[])
    return (
        <motion.div
        initial={{  opacity:0 }}
          animate={{opacity:1 }}
          exit={{opacity:0}}
          transition={{ ease: "easeInOut", duration: 0.6 }}>
            <Navbar />
            <AlertMessage 
            open={openMessage}
            message={message}
            handleClose={handleClose}
            severity={severity}
            />
            <div class="bg-contacto">
                <div class="container">
                    <div class="row">
                        <div class="text-center txt-contacto">
                            <img src={icon_percapita} alt="PERCAPITA" class="img-fluid  icono-headers" />
                            <h1 class="rBlack display-4 mt-5">Contacto</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container py-1">
                <div class="row pb-5">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                    <div class="col-12 col-sm-12 col-md-12 col-md-lg-11">
                        <div class="row" ref={ref}>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
                            <motion.div animate={animation}  class="col-12 col-md-12 col-md-12 col-lg-5">
                                <div class="mb-3">
                                    <h1 class="rLight txt-azul-marino display-5 mb-0">¿Tienes alguna duda?</h1>
                                    <h1 class="rBlack txt-azul-marino display-4 mt-0">Contáctanos</h1>
                                </div>
                                <div class="mb-5">
                                    <img src={circulo_phone} alt="Contactanos" class="img-fluid icon-contact" />
                                    <aside>
                                        <a href="tel:+523336168059" class="a_none_style txt-gris">
                                            <h5 class="oRegular pt-3 txt-gris">33 3616-8059</h5>
                                        </a>
                                    </aside>
                                </div>
                                <div class="mb-5">
                                    <img src={circulo_whats} alt="Contactanos" class="img-fluid icon-contact" />
                                    <aside>
                                        <a href="https://api.whatsapp.com/send?phone=523340094310" class="a_none_style txt-gris">
                                            <h5 class="oRegular pt-3 txt-gris"></h5>
                                        </a>
                                    </aside>
                                </div>
                                <div class="mb-5">
                                    <img src={circulo_mail} alt="Contactanos" class="img-fluid icon-contact" />
                                    <aside>
                                        <a href="mailto:contacto@percapita.mx?Subject=Solicitar%20Informes%20Percapita" class="a_none_style txt-gris">
                                            <h5 class="oRegular pt-2 txt-gris">contacto@percapita.mx</h5>
                                        </a>
                                    </aside>
                                </div>
                                <div class="d-none d-sm-none d-md-block">
                                    <div class="txt-direccion">
                                        <img src={circulo_ubi} alt="Contactanos" class="img-fluid icon-contact mb-4" />
                                        <aside>
                                            <a href="https://goo.gl/maps/XAg38oJRqhg9wSV4A" class="a_none_style txt-gris">
                                                <h5 class="oRegular txt-gris pt-2">Av. Miguel Hidalgo y Costilla 1333, </h5>
                                                <h5 class="oRegular txt-gris">Col Americana, Zona Centro, 44160</h5>
                                                <h5 class="oRegular txt-gris">Guadalajara, Jal.</h5>
                                            </a>
                                        </aside>
                                    </div>
                                </div>
                                <div class="d-block d-sm-block d-md-none">
                                    <div class="">
                                        <img src={circulo_ubi} alt="Contactanos" class="img-fluid icon-contact mb-4" />
                                        <aside>
                                            <a href="https://goo.gl/maps/XAg38oJRqhg9wSV4A" class="a_none_style txt-gris">
                                                <h5 class="oRegular txt-gris pt-2">Av. Miguel Hidalgo y Costilla 1333,
                                                    Col Americana, Zona Centro, 44160
                                                    Guadalajara, Jal.</h5>
                                            </a>
                                        </aside>
                                    </div>
                                </div>


                            </motion.div>
                            <motion.div animate={animation2}  class="col-12 col-md-12 col-md-12 col-lg-6 pt-5">
                                <div class="mx-2">
                                    <div class="bg-gris py-5" style={{boxShadow: '0 -7px 17px #005593'}}>
                                        <div class="mx-5">
                                            <form onSubmit={handleSubmit}>
                                                <div class="mb-4">
                                                <label htmlFor="name" className="form-label label-cuenta">NOMBRE</label>
                                                <input 
                                                    type="text" 
                                                    className={errors.name && touched.name ? "form-control  is-invalid" : "form-control"}
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    id="name" 
                                                    aria-describedby="emailHelp" 
                                                    />
                                                     {errors.name && touched.name &&(
                                                                <div className="invalid-feedback">
                                                                Ingresa tu nombre
                                                                </div>
                                                            )}
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <div class="mb-4">
                                                            <label for="email" class="form-label">EMAIL</label>
                                                        <input 
                                                            type="text" 
                                                            className={errors.email && touched.email ? "form-control  is-invalid" : "form-control"}
                                                            id="email" 
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            aria-describedby="emailHelp"
                                                            placeholder="example@email.com"
                                                             />
                                                            {errors.email && touched.email &&(
                                                                <div className="invalid-feedback">
                                                                Ingresa una correo electrónico
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <div class="mb-4">
                                                        <label htmlFor="phone" className="form-label label-cuenta">TELÉFONO</label>
                                                        <input 
                                                            type="text" 
                                                            className={errors.phone && touched.phone ? "form-control  is-invalid" : "form-control"}
                                                            id="phone" 
                                                            value={values.phone}
                                                            onChange={handleChange}
                                                            aria-describedby="emailHelp"
                                                            placeholder="Teléfono"
                                                             />
                                                            {errors.phone && touched.phone &&(
                                                                <div className="invalid-feedback">
                                                                Ingresa una Teléfono
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <div class="mb-4">
                                                        <label htmlFor="city" className="form-label label-cuenta">CIUDAD</label>
                                                        <input 
                                                            type="text" 
                                                            className={errors.city && touched.city ? "form-control  is-invalid" : "form-control"}
                                                            id="city" 
                                                            value={values.city}
                                                            aria-describedby="emailHelp"
                                                            onChange={handleChange}
                                                            />
                                                            {errors.city && touched.city &&(
                                                                <div className="invalid-feedback">
                                                                Elige una ciudad
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                                        <div class="mb-4">
                                                            <label for="exampleInputEmail1" class="form-label">ESTADO</label>
                                                            <select 
                                                        className={errors.state ? " form-select slect-blue text-light is-invalid" : "form-select slect-blue text-light"}
                                                        aria-label="Default select example"
                                                        id="state"
                                                        name="state"
                                                        value={values.state}
                                                        onChange={handleChange}
                                                        >
                                                            <option value="" disabled>Seleccione un estado</option>
                                                            <option value="Aguascalientes">Aguascalientes</option>
                                                            <option value="Baja California">Baja California</option>
                                                            <option value="Baja California Sur">
                                                                Baja California Sur
                                                            </option>
                                                            <option value="Campeche">Campeche</option>
                                                            <option value="Chiapas">Chiapas</option>
                                                            <option value="Chihuahua">Chihuahua</option>
                                                            <option value="Coahuila de Zaragoza">
                                                                Coahuila de Zaragoza
                                                            </option>
                                                            <option value="Colima">Colima</option>
                                                            <option value="Colima">Distrito Federal</option>
                                                            <option value="Durango">Durango</option>
                                                            <option value="Guanajuato">Guanajuato</option>
                                                            <option value="Guerrero">Guerrero</option>
                                                            <option value="Hidalgo">Hidalgo</option>
                                                            <option value="Jalisco">Jalisco</option>
                                                            <option value="México">México</option>
                                                            <option value="Michoacán de Ocampo">
                                                                Michoacán de Ocampo
                                                            </option>
                                                            <option value="Morelos">Morelos</option>
                                                            <option value="Nayarit">Nayarit</option>
                                                            <option value="Nuevo León">Nuevo León</option>
                                                            <option value="Oaxaca">Oaxaca</option>
                                                            <option value="Puebla">Puebla</option>
                                                            <option value="Querétaro">Querétaro</option>
                                                            <option value="Quintana Roo">Quintana Roo</option>
                                                            <option value="San Luis Potosí">San Luis Potosí</option>
                                                            <option value="Sinaloa">Sinaloa</option>
                                                            <option value="Sonora">Sonora</option>
                                                            <option value="Tabasco">Tabasco</option>
                                                            <option value="Tamaulipas">Tamaulipas</option>
                                                            <option value="Tlaxcala">Tlaxcala</option>
                                                            <option value="Veracruz de Ignacio de la Llave">
                                                                Veracruz de Ignacio de la Llave
                                                            </option>
                                                            <option value="Yucatán">Yucatán</option>
                                                            <option value="Zacatecas">Zacatecas</option>
                                                        </select>
                                                        {errors.state && touched.state &&(
                                                                <div className="invalid-feedback">
                                                                Elige una estado
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mb-5">
                                                <label htmlFor="message" className="form-label label-cuenta">MENSAJE</label>
                                                <textarea 
                                                    className={errors.message && touched.message ? "form-control  is-invalid" : "form-control"}
                                                    id="message" 
                                                    placeholder="Escríbenos tus dudas..."
                                                    value={values.message}
                                                    aria-describedby="emailHelp" 
                                                    onChange={handleChange}
                                                    >

                                                </textarea>
                                                {errors.message && touched.message &&(
                                                    <div className="invalid-feedback">
                                                        Agrega un mensaje
                                                    </div>
                                                )}     
                                                </div>
                                                <div class="mb-3 text-end">
                                                    <button type="submit" class="btn btn-primary btn-solicitar">
                                                        <h6 class="pt-1 rBlack">Enviar</h6>
                                                    </button>
                                                </div>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>  
            </div>
            <div class="container-fluid p-0">
                <div class="row">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.822743056121!2d-103.36793048477467!3d20.67678928619018!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae1b45d64001%3A0xe525da8046177a2b!2sAv.%20Miguel%20Hidalgo%20y%20Costilla%201333%2C%20Col%20Americana%2C%20Zona%20Centro%2C%2044160%20Guadalajara%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1666886732327!5m2!1ses-419!2smx" width="600" height="750" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <Footer />
        </motion.div>
    )
}
