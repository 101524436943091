import React,{useEffect, useRef} from 'react'
import { Footer } from '../../front/Footer'
import { Navbar } from '../../front/Navbar'
import logo_bco from '../../../img/logo-bco.png';
import logo_gde from '../../../img/logo-gde.png';
import productos from '../../../img/productos.png';
import logo_financiamiento from '../../../img/logo-financiamiento.png';
import logo_dire from '../../../img/logo-dire.png';
import { useAnimation, useInView, motion } from 'framer-motion';
export const Financiamiento = () => {
    const ref = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null)
    const ref6 = useRef(null)
    const ref7= useRef(null);
    const inView = useInView(ref)
    const inView2 = useInView(ref2)
    const inView3 = useInView(ref3)
    const inView4 = useInView(ref4)
    const inView5 = useInView(ref5)
    const inView6 = useInView(ref6)
    const inView7 = useInView(ref7)
    const animation = useAnimation();
    const animation2 = useAnimation();
    const animation3 = useAnimation();
    const animation4 = useAnimation();
    const animation5 = useAnimation();
    const animation6 = useAnimation();
    const animation7 = useAnimation();
    const animation11 = useAnimation();
    const animation12 = useAnimation();
    const animation13 = useAnimation();
    const animation14 = useAnimation();
    
    const animation8 = useAnimation();
    const animation9 = useAnimation();
    const animation10 = useAnimation();
    const animation15 = useAnimation();
    const animation16 = useAnimation();
    const animation17 = useAnimation();
    const animation18 = useAnimation();
    const animation19 = useAnimation();
    const animation20 = useAnimation();
    const animation21 = useAnimation();
    const animation22 = useAnimation();
    useEffect(()=>{
        if(inView){
            animation.start({
              x:0, 
              opacity:1, 
              scale: 1,
              transition:{ ease: "easeInOut", duration: 0.8 }
            })
          }
          if(!inView){
            animation.start({
              x:'50%', 
              opacity:0, 
              scale: 0.5
            })
          }
          if(inView2){
            animation2.start({
              x:0, 
              opacity:1, 
              scale: 1,
              transition:{ ease: "easeInOut", duration: 1 }
            })
            animation3.start({
                x:0, 
                opacity:1, 
                scale: 1,
                transition:{ ease: "easeInOut", duration: 1 }
              })
              animation4.start({
                x:0, 
                opacity:1, 
                scale: 1,
                transition:{ ease: "easeInOut", duration: 1 }
              })
          }
          if(!inView2){
            animation2.start({
              x:'50%', 
              opacity:0, 
              scale: 0.5
            })
            animation3.start({
                x:'50%', 
                opacity:0, 
                scale: 0.5
              })
              animation4.start({
                x:'50%', 
                opacity:0, 
                scale: 0.5
              })
          }
          if(inView3){
            animation5.start({
              x:0, 
              opacity:1, 
              scale: 1,
              transition:{ ease: "easeInOut", duration: 0.8 }
            })
          }
          if(!inView3){
            animation5.start({
              x:'50%', 
              opacity:0, 
              scale: 0.5
            })
          }
          if(inView4){
            animation6.start({
                x:0, 
                opacity:1, 
                scale: 1,
                transition:{ ease: "easeInOut", duration: 0.8 }
              })
          }
          if(!inView4){
            animation6.start({
                x:'50%', 
                opacity:0, 
                scale: 0.5
              })
          }
          if(inView5){
            animation7.start({
                x:0, 
                opacity:1, 
                scale: 1,
                transition:{ ease: "easeInOut", duration: 0.8 }
              })
          }
          if(!inView5){
            animation7.start({
                x:'50%', 
                opacity:0, 
                scale: 0.5
              })
          }
},[inView, inView2,inView3, inView4, inView5])

useEffect(() => {
    document.title = "Percapita - Financiamiento"
},[])
    return (
        <motion.div
        initial={{  opacity:0 }}
          animate={{opacity:1 }}
          exit={{opacity:0}}
          transition={{ ease: "easeInOut", duration: 0.6 }}>
            <Navbar />
            <div class="bg-financiamiento" ref={ref}>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                            <motion.div animate={animation} class="text-center txt-financiamiento">
                                <img src={logo_bco} alt="PERCAPITA" class="img-fluid" />
                                <div class="text-end me-5">
                                    <h6 class="rLight">FINANCIAMIENTOS</h6>
                                </div>
                            </motion.div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>

                    </div>
                </div>
            </div>
            <div class="d-none d-sm-none d-md-block">
                <div class="container-fluid bg-gris">
                    <div class="row py-5">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                                <motion.div animate={animation2}>
                                <h1 class="text-center rLight txt-azul-marino txt-gracias">
                                    Gracias a
                                </h1>
                                </motion.div>                                
                                <motion.div animate={animation3} class="text-end mb-4">
                                    <img src={logo_gde} alt="PERCAPITA" class="img-fluid logo-gde" />
                                    <h3 class="rMedium txt-azul h6">FINANCIAMIENTOS</h3>
                                </motion.div>
                                <motion.div animate={animation4} class="txt-ayuda3 text-center">
                                    <div class="ms-5 mb-4">
                                        <h1 class="rLight txt-azul2 display-6">Te ayudamos a adquirir </h1>
                                        <h1 class="rBlack txt-azul2 display-3">tus productos</h1>
                                        <h1 class="rBlack txt-azul display-3">preferidos</h1>
                                    </div>
                                </motion.div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-7" ref={ref2}>
                                <img src={productos} alt="PERCAPITA" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-block d-sm-block d-md-none" ref={ref3}>
                <div class="container-fluid bg-gris">
                    <div class="row py-5">
                        <div class="row">
                            <motion.div animate={animation5} class="col-12 col-sm-12 col-md-12 col-lg-5">
                                <h1 class="text-center rLight txt-azul-marino txt-gracias">
                                    Gracias a
                                </h1>
                                <div class="text-end mb-4">
                                    <img src={logo_gde} alt="PERCAPITA" class="img-fluid logo-gde" />
                                    <h3 class="rMedium txt-azul h6">FINANCIAMIENTOS</h3>
                                </div>
                                <div class="txt-ayuda3 text-center">
                                    <div class="mb-4">
                                        <h1 class="rLight txt-azul2 display-6">Te ayudamos a adquirir </h1>
                                        <h1 class="rBlack txt-azul2 display-3">tus productos</h1>
                                        <h1 class="rBlack txt-azul display-3">preferidos</h1>
                                    </div>
                                </div>
                            </motion.div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-7 pt-3">
                                <img src={productos} alt="PERCAPITA" class="img-fluid pt-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <motion.div animate={animation6} class="bg-solarum" ref={ref4}>
                <div class="container">
                    <div class="row">
                        <div class="text-end py-5">
                            <img src={logo_financiamiento} alt="PERCAPITA FINANCIAMIENTOS" class="img-fluid" />
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8">
                            <div class="text-end">
                                <div class="txt-solarum">
                                    <h4 class="rLight">Disfruta de este tequila</h4>
                                    <h1 class="rBlack">Con ojulas de oro comestible de 24K.</h1>
                                    <div class="mt-3">
                                        <a href="#" class="btn btn-primary btn-solicitar">
                                            <h6 class="pt-1 rBlack">Comenzar</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </motion.div>
            <motion.div animate={animation7} class="bg-dire" ref={ref5}>
                <div class="container p-0">
                    <div class="row">
                        <div class="text-end py-5">
                            <img src={logo_financiamiento} alt="PERCAPITA FINANCIAMIENTOS" class="img-fluid" />
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-8">
                            <div class="text-center">
                                <img src={logo_dire} alt="DIRÉ" class="img-fluid logo-dire" />

                                <div class="txt-dire">
                                    <h4 class="rLight h3">Los mejores planes de telefonía</h4>
                                    <h1 class="rBlack">con cobertura nacional e internacional</h1>
                                    <div class="mt-3">
                                        <a href="#" class="btn btn-primary btn-solicitar">
                                            <h6 class="pt-1 rBlack">Comenzar</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
            <Footer />
        </motion.div>
    )
}
