import React from 'react';
import { Document, Page } from 'react-pdf';
import pdfUrl from './catycomisiones.pdf'
const PDFViewer = () => {
  return (
    <div>
      <Document file={'https://percapita.mx/catycomisiones/catycomisiones.pdf'}>
        <Page pageNumber={1} />
      </Document>
    </div>
  );
};

export default PDFViewer;