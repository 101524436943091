import React, {useEffect, useState} from 'react'
import { Footer } from '../../front/Footer'
import { Navbar } from '../../front/Navbar'
import img_estrategias from '../../../img/img-estrategias.png';
import calendario from '../../../img/calendario.png';
import flechai  from '../../../img/flechai.png';
import flechad  from '../../../img/flechaD.png';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';

export const BlogD = () => {
    const {id} = useParams();
    const source = 'https://percapita-development.s3.us-west-2.amazonaws.com/df70d12b-da4b-4106-8721-85f64a432be1/afd9cffc-a-selfieIne.jpg'
    const[data,setData] = useState([]);
    const [content, setContent] = useState([])
    useEffect(() => {
        const data = async() => {
            await fetch(`https://app.percapita.mx/api/blog-data/${id}`,{
                method:'POST'
            })
            .then((data) => data.json())
            .then(data => {
                setData(data)
                console.log(data.data.length)
                if(data.data){
                    console.log(data.data.data.split('. '))
                    setContent(data.data?.data.split('. '))
                    document.title = data.name
                }
                
            })
        }
        data();
    },[])
    return (
        <div>
            <Navbar />
            <div class="container py-2">
                <div class="container">
                    <h2 class="rBlack txt-azul-marino display-4 mt-0">{data.name}</h2>
                    <div class="d-flex justify-content-center">
                        <img src={data.image} alt="Contactanos" width="50%" class="img-fluid" />
                    </div>
                    <br />
                    <div class="row">
                        <div class="col">
                            <div class="d-flex justify-content-start mt-3">
                                <img class="mt-2" src={calendario} alt="Contactanos" width="25" height="25" />
                                {moment(data.datePost).format('LL')}
                            </div>
                        </div>
                        <div class="col">
                            <div class="d-flex justify-content-end">
                                <div class="bg-solicitar-cred py-2">
                                    <a class="nav-link text-white">
                                        <h6>{data.category}</h6>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container mt-5">
                        {/* <p>
                            En los últimos años las crisis financieras globales han sido una constante, especialmente desde el
                            año 2020 con el inicio de la pandemia.
                            <br /><br />
                            Los cierres de empresas debido a la falta de mercado y la ola de desempleos que esto significó, nos
                            enfrentó a una crisis económica que en muchos casos -lamentablemente- aún se mantiene.
                            <br /><br />
                            No obstante, esta nueva realidad económica despertó en la mayoría de nosotros una nueva consciencia,
                            gracias a la que en la actualidad se ha vuelto cada vez más frecuente saber de familiares y
                            allegados buscando alternativas que les ayuden a prever futuras crisis.
                            <br /><br />
                            Las crisis económicas pueden presentarse en cualquier época y no solo responden a factores externos
                            como la pandemia, sino incluso pueden presentarse como consecuencia de imprevistos familiares o
                            enfermedades.
                            <br /><br />
                            Por eso, es importante tomar acciones y estrategias oportunas que nos permitan estar preparados para
                            sobrevivir a una crisis económica.
                            <br /><br />
                            A continuación te platicamos sobre algunas estrategias que puedes poner en práctica para sobrevivir
                            a una crisis económica.
                            <br /><br />
                            1.- Instrúyete
                            <br /><br />
                            Mientras la crisis pasa es muy importante que te nutras de educación financiera, esta te ayudará a
                            manejar tu dinero y a cómo distribuir tus ingresos en adelante, para -en la medida de lo posible-
                            pasar por nuevas crisis.
                            <br /><br />
                            2.- Ahorra
                            <br /><br />
                            El ahorro es la mejor estrategia que puedes poner en práctica no solo para sobrevivir a una crisis
                            económica, sino también para estabilizarse financieramente y tener la tranquilidad de contar con un
                            “colchón” en caso de emergencias.
                            <br /><br />
                            3.- Planifica
                            <br /><br />
                            Planificar es una estrategia que debemos poner en práctica no solo para sobrevivir a una crisis
                            económica, sino que debemos convertirla en un hábito de vida. La planificación de las finanzas
                            personales ayudará a manejar más responsablemente nuestras finanzas.
                            <br /><br />
                            Para esto se pueden usar diferentes métodos, uno de ellos es la regla 70-30 en la que destinas el
                            70% de tus ingresos para los gastos fijos y el otro 30% para entretenimiento, pago de deudas y
                            ahorro.
                            <br /><br />
                            Otra manera es llevar el control de cada gasto y apuntarlos, de manera que esto te permita darte
                            cuenta de cuáles son tus gastos hormiga o gastos prescindibles y comenzar a evitarlos.
                            <br /><br />
                            4.- Evita endeudarte
                            <br /><br />
                            En medio de una crisis económica evitar adquirir nuevas deudas es una excelente estrategia. Si bien
                            en primera instancia no representa un ingreso, si representa un ahorro de dinero y de
                            preocupaciones.
                            <br /><br />
                            Adquirir una deuda sólo generará intereses y responsabilidades financieras que muy probablemente no
                            estemos preparados para cubrir.
                        </p> */}
                        <p>
                        { content && (
                            content.map((item) =>(
                                <>
                                {item}
                                <br></br><br></br>
                                </>
                            ))
                        )
                            
                        }
                        </p>
                        
                    </div>
                </div>
            </div>
            <div class="row">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div class="d-flex justify-content-center mt-5 mb-4">
                                        <a href='/blog' class="btn btn-shadow-2">
                                            <img src={flechai} alt="PRADO'S" class="img-fluid mrirht" width="10" />
                                            Regresar
                                        </a>
                                    </div>
                                </div>
                            </div>
            <Footer />
        </div>
    )
}
