import React, { useEffect, useState, useRef } from "react";
import { Footer } from "../../front/Footer";
import { Navbar } from "../../front/Navbar";
import icon_percapita from "../../../img/icon-percapita.png";
import familianosotros from "../../../img/familia-nosotros.png";
import elcreditorapido from "../../../img/el-credito-rapido.png";
import logogde from "../../../img/logo-gde.png";
import logobco from "../../../img/logo-bco.png";
import solicitudlinea from "../../../img/solicitud-linea.png";
import confirmacelular from "../../../img/confirma-celular.png";
import subedocumentos from "../../../img/sube-documentos.png";
import prestamo from "../../../img/prestamo.png";
import productos from "../../../img/productos.png";
import logo from "../../../img/logo.png";

//
import logo_pabs2 from "../../../img/logo_pabs.png";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import icono_mujer from "../../../img/icono_mujer.png";

import icono_percapita from "../../../img/icono-percapita.png";
import ana_luisa from "../../../img/ana-luisa.png";
import angelica_luis from "../../../img/angelica-luis.png";
import { useAnimation, useInView, motion } from "framer-motion";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",

  maxHeight: "500px",
  background: "radial-gradient(circle at top left, #005593, #36c9d0)",
  boxShadow: 29,
  overflowY: "auto",
};
export const Nosotros = () => {
  const [open2, setOpen2] = React.useState(false);
  const handleClose = () => setOpen2(false);
  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const inView = useInView(ref);
  const inView2 = useInView(ref2);
  const inView3 = useInView(ref3);
  const inView4 = useInView(ref4);
  const inView5 = useInView(ref5);
  const inView6 = useInView(ref6);
  const inView7 = useInView(ref7);
  const animation = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();
  const animation4 = useAnimation();
  const animation5 = useAnimation();
  const animation6 = useAnimation();
  const animation7 = useAnimation();
  const animation11 = useAnimation();
  const animation12 = useAnimation();
  const animation13 = useAnimation();
  const animation14 = useAnimation();

  const animation8 = useAnimation();
  const animation9 = useAnimation();
  const animation10 = useAnimation();
  const animation15 = useAnimation();
  const animation16 = useAnimation();
  const animation17 = useAnimation();
  const animation18 = useAnimation();
  const animation19 = useAnimation();
  const animation20 = useAnimation();
  const animation21 = useAnimation();
  const animation22 = useAnimation();
  useEffect(() => {
    if (inView) {
      console.log(1);
      animation.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
      animation2.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2 },
      });
      animation3.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.6 },
      });
    }
    if (!inView) {
      animation.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation2.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation3.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView]);
  useEffect(() => {
    if (inView2) {
      animation4.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1 },
      });
    }
    if (!inView2) {
      animation4.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }

    if (inView4) {
      animation9.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
      animation10.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2 },
      });
    }
    if (!inView4) {
      animation9.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation10.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView2, inView3, inView4]);
  useEffect(() => {
    if (inView3) {
      console.log("inView3");
      animation5.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
      animation6.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2 },
      });
      animation7.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.6 },
      });
      animation8.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 2 },
      });
    }
    if (!inView3) {
      animation5.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation6.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation7.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation8.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }

    if (inView5) {
      console.log("inView3");
      animation11.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1 },
      });
      animation12.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.4 },
      });
      animation13.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.8 },
      });
      animation14.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 2.2 },
      });
    }
    if (!inView5) {
      animation11.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation12.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation13.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation14.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
    if (inView6) {
      animation15.start({
        x: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1 },
      });
    }
    if (!inView6) {
      animation15.start({
        x: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
    if (inView7) {
      animation16.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1 },
      });
      animation17.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.4 },
      });
      animation18.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.8 },
      });
      animation19.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 2.2 },
      });
    }
    if (!inView7) {
      animation16.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation17.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation18.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation19.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView3, inView5, inView6, inView7]);

  useEffect(() => {
    document.title = "Percapita - Nosotros"
},[])
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <Navbar />
      <Modal
        keepMounted
        open={open2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* //<ScrollView width="100%" height="100%"> */}
        <Grid container spacing={2} sx={style}>
          <div className="modal-content">
            <div className="d-flex justify-content-end p-1  ">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center mb-3">
                <img
                  src={logo}
                  alt="PRADO'S"
                  width="220"
                  className="img-fluid"
                />
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center">
                    <img
                      src={logo_pabs2}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Tengo título PABS{" "}
                    </h4>
                    <h5 className="rLight mt-4">
                      Tu crédito ya está <br />
                      Pre-autorizado
                    </h5>

                    <div className="mt-4">
                      <a
                        href="https://app.percapita.mx/solicitud-monto/titulo"
                        className="btn btn-primary btn-conoce-azul"
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                    <div className="mt-4">
                      <p className="rLight mt-4">
                        ¿Qué es un Título PABS? <br />
                        <a
                          href="https://www.pabsmr.org/que-es-un-titulo-pabs/"
                          target="_blank"
                          style={{ color: "white" }}
                        >
                          Click aqui
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <div className="d-flex justify-content-center">
                    <img
                      src={icono_mujer}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Quiero un <br /> crédito personal{" "}
                    </h4>
                    <h5 className="rLight mt-4">
                      Tu crédito en <br />
                      48 hrs*
                    </h5>

                    <div className="mt-4">
                      <a
                        href="https://app.percapita.mx/solicitud-monto/personal"
                        className="btn btn-primary btn-conoce-azul"
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Modal>
      <div class="d-none d-sm-none d-md-block" ref={ref}>
        <div class="bg-nosotros py-5">
          <div class="container pt-5">
            <div class="row py-5">
              <div class="text-center pt-5">
                <motion.div animate={animation} class="mb-4">
                  <img
                    src={icon_percapita}
                    alt="PERCAPITA"
                    class="img-fluid icono-headers"
                  />
                  <h6 class="rBold h4">Nosotros</h6>
                </motion.div>
                <div>
                  <motion.div animate={animation2}>
                    <h1 class="rLight">Queremos que tengas</h1>
                  </motion.div>
                  <motion.div animate={animation3}>
                    <h1 class="rBlack display-4">
                      libertad <b class="txt-azul">económica</b>
                    </h1>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none" ref={ref2}>
        <div class="bg-nosotros-phone py-3">
          <div class="container">
            <div class="row">
              <motion.div class="text-center" animate={animation4}>
                <div class="mb-4">
                  <img
                    src={icon_percapita}
                    alt="PERCAPITA"
                    class="img-fluid icon-percapita-nosotro"
                  />
                </div>
                <div class="">
                  <h1 class="rLight display-3">
                    Queremos <br /> que tengas
                  </h1>
                  <h1 class="rBlack display-2">
                    libertad <br /> <b class="txt-azul">económica</b>
                  </h1>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-none d-md-block">
        <div class="bg-familia-index pt-5">
          <div class="container pt-5">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                <div class="txt-libertad">
                  <motion.div animate={animation5}>
                    <h2 class="rLight h2">Queremos que tus necesidades</h2>
                  </motion.div>
                  <motion.div animate={animation6}>
                    <h1 class="rBlack">dejen de ser </h1>
                  </motion.div>
                  <motion.div animate={animation7}>
                    <h1 class="rBlack txt-azul">preocupaciones</h1>
                  </motion.div>
                </div>
                <motion.div class="mt-5" animate={animation8}>
                  <p class="rRegular h5">En Percapita queremos ayudarte </p>
                  <p class="rRegular h5">a que tus sueños y aspiraciones </p>
                  <p class="rRegular h5">se vuelvan metas alcanzables.</p>
                </motion.div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                <div class="text-center" ref={ref3}>
                  <img
                    src={familianosotros}
                    alt="PERCAPITA"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none" ref={ref4}>
        <div class="bg-familia-index-phone pt-5">
          <div class="container pt-5">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                <motion.div class="txt-libertad" animate={animation9}>
                  <h2 class="rLight h2">Queremos que tus necesidades</h2>
                  <h1 class="rBlack">dejen de ser </h1>
                  <h1 class="rBlack txt-azul">preocupaciones</h1>
                </motion.div>
                <motion.div class="mt-5" animate={animation10}>
                  <p class="rRegular h5">En Percapita queremos ayudarte </p>
                  <p class="rRegular h5">a que tus sueños y aspiraciones </p>
                  <p class="rRegular h5">se vuelvan metas alcanzables.</p>
                </motion.div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-7 pb-5">
                <div class="text-center">
                  <img
                    src={familianosotros}
                    alt="PERCAPITA"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-none d-md-block" ref={ref6}>
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
              <img src={elcreditorapido} alt="PERCAPITA" class="img-fluid " />
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
              <motion.div animate={animation15} class="mx-5">
                <img
                  src={logogde}
                  alt="PERCAPITA"
                  class="img-fluid logo-credito"
                />
                <div class="my-5">
                  <h2 class="rLight txt-azul-marino display-5 mb-0">
                    El crédito
                  </h2>
                  <h2 class="rBlack txt-azul-marino display-5">más rápido</h2>
                </div>
                <div class="rRegular txt-gris h5">
                  <p>Somos la institución que otorga el crédito </p>
                  <p>más rápido y con los menores requisitos </p>
                  <p>posibles ofreciendo un servicio profesional, </p>
                  <p>oportuno y personalizado.</p>
                </div>
                <div class="mt-5">
                  {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-solicitar">
                                        <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                                    </a> */}
                  {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
                  <a
                    href="#"
                    onClick={() => setOpen2(true)}
                    class="btn btn-primary btn-conoce-azul"
                  >
                    <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                  </a>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 mb-4">
              <div class="mx-5">
                <div class="my-5">
                  <h2 class="rLight txt-azul-marino display-5 mb-0">
                    El crédito
                  </h2>
                  <h2 class="rBlack txt-azul-marino display-5">más rápido</h2>
                </div>
                <div class="rRegular txt-gris h5">
                  <p>
                    Somos la institución que otorga el crédito más rápido y con
                    los menores requisitos posibles ofreciendo un servicio
                    profesional, oportuno y personalizado.
                  </p>
                </div>
                <div class="mt-5">
                  {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-solicitar">
                                        <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                                    </a> */}
                  {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
                  <a
                    href="#"
                    onClick={() => setOpen2(true)}
                    class="btn btn-primary btn-conoce-azul"
                  >
                    <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
              <img src={elcreditorapido} alt="PERCAPITA" class="img-fluid " />
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-none d-md-block">
        <div class="bg-familia-nosotros mt-5">
          <div class="container">
            <div class="row">
              <div class="text-center">
                <div class="txt-fam-nosotros">
                  <h1 class="rLight">Tu libertad económica</h1>
                  <h1 class="rBlack">con Percapita</h1>
                </div>
                <div class="mt-3">
                  {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-conoce-azul">
                                        <h6 class="rBlack txt-azul-marino pt-1">Solicitar crédito</h6>
                                    </a> */}
                  {/* <button disabled class="btn btn-primary btn-conoce-azul">
                              <h6 class="rBlack txt-azul-marino pt-1">Solicitar crédito</h6>
                            </button> */}
                  <a
                    href="#"
                    onClick={() => setOpen2(true)}
                    class="btn btn-primary btn-conoce-azul"
                  >
                    <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="bg-familia-nosotros-phone">
          <div class="container">
            <div class="row pt-4">
              <div class="text-center">
                <h1 class="rBlack display-2 mb-0">Tu libertad</h1>
                <h1 class="rBlack txt-azul display-2 mt-0">económica</h1>
                <img
                  src={logobco}
                  alt="PERCAPITA"
                  class="img-fluid logo-fam-nosotros"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none d-sm-none d-md-block">
        <div class="container-fluid p-0">
          <div class="row py-4">
            <div class="text-center">
              <h2 class="txt-azul-marino rLight mb-0">Sólo sigue</h2>
              <h1 class="txt-azul-marino rExtraBold mt-0">
                4 sencillos <b class="txt-azul">pasos</b>
              </h1>
            </div>
          </div>
          <div class="container py-3" ref={ref5}>
            <div class="row mb-5">
              <motion.div
                animate={animation11}
                class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
              >
                <div class="mx-3">
                  <div class="bg-cards py-3">
                    <div class="pt-3">
                      <img
                        src={solicitudlinea}
                        alt="SOLICITUD EN LÍNEA"
                        class="img-fluid mb-3 icono-solicitud"
                      />
                      <p class="txt-azul txt-paso rRegular">Paso 01</p>
                    </div>
                    <div class="mb-3">
                      <h6 class="txt-azul-marino h5 rExtraBold">Solicitud</h6>
                      <h6 class="txt-azul-marino h5 rExtraBold">en línea</h6>
                    </div>
                    <div class="rRegular ">
                      <p class="txt-gris">Llena el formulario en </p>
                      <p class="txt-gris">línea con tu </p>
                      <p class="txt-gris">información personal.</p>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                animate={animation12}
                class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
              >
                <div class="mx-3">
                  <div class="bg-cards py-3">
                    <div class="pt-3">
                      <img
                        src={confirmacelular}
                        alt="CONFIRMA TU CELULAR"
                        class="img-fluid mb-3 icono-celular"
                      />
                      <p class="txt-azul txt-paso rRegular">Paso 02</p>
                    </div>
                    <div class="mb-3">
                      <h6 class="txt-azul-marino h5 rExtraBold">Confirma</h6>
                      <h6 class="txt-azul-marino h5 rExtraBold">tu celular</h6>
                    </div>
                    <div class="rRegular ">
                      <p class="txt-gris">Confirma tu cuenta </p>
                      <p class="txt-gris">con el código que </p>
                      <p class="txt-gris">recibirás en tu celular.</p>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                animate={animation13}
                class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
              >
                <div class="mx-3">
                  <div class="bg-cards py-3">
                    <div class="pt-3">
                      <img
                        src={subedocumentos}
                        alt="SUBE TUS DOCUMENTOS"
                        class="img-fluid mb-3 icono-documentos"
                      />
                      <p class="txt-azul txt-paso rRegular">Paso 03</p>
                    </div>
                    <div class="mb-3">
                      <h6 class="txt-azul-marino h5 rExtraBold">Subes tus</h6>
                      <h6 class="txt-azul-marino h5 rExtraBold">documentos</h6>
                    </div>
                    <div class="rRegular ">
                      <p class="txt-gris">Identificación oficial y</p>
                      <p class="txt-gris">un comprobante de</p>
                      <p class="txt-gris">domicilio.</p>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                animate={animation14}
                class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
              >
                <div class="mx-3">
                  <div class="bg-cards py-3">
                    <div class="pt-3">
                      <img
                        src={prestamo}
                        alt="TU PRESTAMO EN 24 HRS."
                        class="img-fluid mb-3 icono-prestamo"
                      />
                      <p class="txt-azul txt-paso rRegular">Paso 04</p>
                    </div>
                    <div class="mb-3">
                      <h6 class="txt-azul-marino h5 rExtraBold">Tu préstamo</h6>
                      <h6 class="txt-azul-marino h5 rExtraBold">en 48 hrs*.</h6>
                    </div>
                    <div class="rRegular ">
                      <p class="txt-gris">Tu dinero estará listo </p>
                      <p class="txt-gris">en 48 horas*.</p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
            <div class="row">
              <div class="text-center">
                {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-solicitar">
                                    <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                                </a> */}
                {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
                <a
                  href="#"
                  onClick={() => setOpen2(true)}
                  class="btn btn-primary btn-conoce-azul"
                >
                  <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="container py-5">
          <div class="row">
            <div class="text-center mb-2">
              <h2 class="txt-azul-marino rLight display-3">Sólo sigue</h2>
              <h1 class="txt-azul-marino rExtraBold display-3">
                4 sencillos <b class="txt-azul">pasos</b>
              </h1>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              <div class="mx-2 mb-4">
                <div class="bg-cards py-3">
                  <div class="row justify-content-center">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 pt-3">
                      <div class="text-start ms-4">
                        <p class="txt-azul txt-paso rRegular">Paso 01</p>
                      </div>
                      <div class="ms-4">
                        <img
                          src={solicitudlinea}
                          alt="SOLICITUD EN LÍNEA"
                          class="img-fluid mb-3 icono-solicitud"
                        />
                      </div>
                    </div>
                    <div class="col-8 col-sm-8 col-md-8 col-lg-8">
                      <div class="ms-2">
                        <div class="mb-3 text-start">
                          <h6 class="txt-azul-marino h2 rExtraBold mb-0">
                            Solicitud
                          </h6>
                          <h6 class="txt-azul-marino h2 rExtraBold">
                            en línea
                          </h6>
                        </div>
                        <div class="rRegular text-start">
                          <p class="txt-gris">
                            Llena el formulario <br /> en línea con tu
                            información <br /> personal.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              <div class="mx-2 mb-4">
                <div class="bg-cards py-3">
                  <div class="row justify-content-center">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 pt-2">
                      <div class="text-start ms-4">
                        <p class="txt-azul txt-paso rRegular">Paso 02</p>
                      </div>
                      <div class="ms-4">
                        <img
                          src={confirmacelular}
                          alt="CONFIRMA TU CELULAR"
                          class="img-fluid mb-3 icono-celular"
                        />
                      </div>
                    </div>
                    <div class="col-8 col-sm-8 col-md-8 col-lg-8">
                      <div class="ms-2">
                        <div class="mb-3 text-start">
                          <h6 class="txt-azul-marino h2 rExtraBold mb-0">
                            Confirma tu
                          </h6>
                          <h6 class="txt-azul-marino h2 rExtraBold">celular</h6>
                        </div>
                        <div class="rRegular text-start">
                          <p class="txt-gris">
                            Confirma tu cuenta
                            <br /> con el código que <br /> recibirás en tu{" "}
                            <br /> celular.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              <div class="mx-2 mb-4">
                <div class="bg-cards py-3">
                  <div class="row justify-content-center">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 pt-2">
                      <div class="text-start ms-4">
                        <p class="txt-azul txt-paso rRegular">Paso 03</p>
                      </div>
                      <div class="ms-4">
                        <img
                          src={subedocumentos}
                          alt="SUBE TUS DOCUMENTOS"
                          class="img-fluid mb-3 icono-documentos"
                        />
                      </div>
                    </div>
                    <div class="col-8 col-sm-8 col-md-8 col-lg-8">
                      <div class="ms-2">
                        <div class="mb-3 text-start">
                          <h6 class="txt-azul-marino h2 rExtraBold mb-0">
                            Sube tus
                          </h6>
                          <h6 class="txt-azul-marino h2 rExtraBold mt-0">
                            documentos
                          </h6>
                        </div>
                        <div class="rRegular text-start">
                          <p class="txt-gris">
                            Sube tus
                            <br /> documentos
                            <br /> oficiales que se <br /> solicitan.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              <div class="mx-2 mb-4">
                <div class="bg-cards py-3">
                  <div class="row justify-content-center">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 pt-3">
                      <div class="text-start ms-4">
                        <p class="txt-azul txt-paso rRegular">Paso 04</p>
                      </div>
                      <div class="ms-4">
                        <img
                          src={prestamo}
                          alt="TU PRESTAMO EN 24 HRS."
                          class="img-fluid mb-3 icono-prestamo"
                        />
                      </div>
                    </div>
                    <div class="col-8 col-sm-8 col-md-8 col-lg-8">
                      <div class="ms-2">
                        <div class="mb-3 text-start">
                          <h6 class="txt-azul-marino h2 rExtraBold mb-0">
                            Tu préstamo
                          </h6>
                          <h6 class="txt-azul-marino h2 rExtraBold mt-0">
                            en 48 hrs*.
                          </h6>
                        </div>
                        <div class="rRegular text-start">
                          <p class="txt-gris">
                            Confirma tu cuenta
                            <br /> con el código que
                            <br /> recibirás en tu <br /> celular.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="d-none d-sm-none d-md-block">
                <div class="container-fluid bg-gris pt-5">
                    <div class="row pt-5 pb-2">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-2"></div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                                <div class="txt-ayuda2">
                                    <div class="ms-5 mb-4">
                                        <h1 class="rLight txt-azul2">Termina de</h1>
                                        <h1 class="rBlack txt-azul2">Equipar tu casa</h1>
                                    </div>
                                    <div class="ms-5 mb-4">
                                        <h4 class="rMedium txt-azul2">A través de financiamientos</h4>
                                        <h4 class="rMedium txt-azul2">personales.</h4>
                                    </div>
                                    <div class="ms-5">
                                        <a href="#" class="btn btn-primary btn-solicitar">
                                            <h6 class="pt-1 rBlack">Conoce más</h6>
                                        </a>
                                    </div>
                                </div>
                                <div class="text-end mt-5 me-5">
                                    <img src={logogde} alt="PERCAPITA" class="img-fluid logo-gde" />
                                    <h3 class="rLight txt-azul h6">FINANCIAMIENTOS</h3>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                <img src={productos} alt="PERCAPITA" class="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-block d-sm-block d-md-none">
                <div class="container-fluid bg-gris">
                    <div class="row py-5">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-2"></div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                                <div class="text-end mb-4">
                                    <img src={logogde} alt="PERCAPITA" class="img-fluid logo-gde" />

                                    <h3 class="rLight txt-azul h6">FINANCIAMIENTOS</h3>
                                </div>
                                <div class="txt-ayuda text-center">
                                    <div class="ms-5 mb-4">
                                        <h1 class="rLight txt-azul2 h3">Te ayudamos a adquirir tus</h1>
                                        <h1 class="rBlack txt-azul2 h3">productos preferidos</h1>
                                    </div>
                                    <div class="ms-5 mb-4">
                                        <h4 class="rMedium txt-azul2 display-4">A través de </h4>
                                        <h4 class="rMedium txt-azul2 display-4">financiamientos</h4>
                                        <h4 class="rMedium txt-azul2 display-4">personales.</h4>
                                    </div>
                                    <div class="ms-5">
                                        <a href="#" class="btn btn-primary btn-solicitar">
                                            <h6 class="pt-1 rBlack">Conoce más</h6>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-6 pt-3">
                                <img src={productos} alt="PERCAPITA" class="img-fluid pt-5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* Carrousel */}
      <div class="d-none d-sm-none d-md-block" ref={ref7}>
        <div class="bg-reviews py-5">
          <div class="container">
            <div class="row">
              <div class="text-center mb-5 display-6">
                <motion.div animate={animation16}>
                  <h3 class="rLight mb-0">Más de 1,500 personas</h3>
                </motion.div>

                <motion.div animate={animation17}>
                  <h1 class="rBlack my-0 display-5">han adquirido su</h1>
                </motion.div>

                <motion.div animate={animation18}>
                  <h1 class="rBlack txt-azul mt-0 display-4">
                    libertad económica
                  </h1>
                </motion.div>
              </div>
              <motion.div animate={animation19} class="glider-contain">
                <div class="glider">
                  <div>
                    <div class="card-review">
                      <div class="container">
                        <div class="row">
                          <div class="text-end">
                            <img
                              src={icono_percapita}
                              alt="PERCAPITA"
                              class="img-fluid icono-per-review"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 py-3">
                            <img
                              src={ana_luisa}
                              alt="PERCAPITA"
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div class="mb-3">
                              <h5 class="rExtraBold txt-azul-marino mb-0">
                                Ana Luisa
                              </h5>
                              <h5 class="rExtraBold txt-azul-marino mt-0">
                                Rodríguez
                              </h5>
                              <h5 class="rSemiBold txt-azul">
                                Préstamo de $5,000
                              </h5>
                            </div>
                            <div class="oRegular txt-gris">
                              <p>
                                Gracias al préstamo de $5,000 que adquirí con
                                Percapita pude regalarle un viaje a la playa a
                                toda mi familia, estuvimos muy contentos.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="card-review">
                      <div class="container">
                        <div class="row">
                          <div class="text-end">
                            <img
                              src={icono_percapita}
                              alt="PERCAPITA"
                              class="img-fluid icono-per-review"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 py-3">
                            <img
                              src={angelica_luis}
                              alt="PERCAPITA"
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div class="mb-3">
                              <h5 class="rExtraBold txt-azul-marino mb-0">
                                Angélica Luis
                              </h5>
                              <h5 class="rExtraBold txt-azul-marino mt-0">
                                De Velasco
                              </h5>
                              <h5 class="rSemiBold txt-azul">
                                Préstamo de $10,000
                              </h5>
                            </div>
                            <div class="oRegular txt-gris">
                              <p>
                              Gracias al préstamo de $10,000.00 que me dio Percapita pude invertir en mi pequeño negocio de comida, para generar más ingresos.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="card-review">
                      <div class="container">
                        <div class="row">
                          <div class="text-end">
                            <img
                              src={icono_percapita}
                              alt="PERCAPITA"
                              class="img-fluid icono-per-review"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 py-3">
                            <img
                              src={ana_luisa}
                              alt="PERCAPITA"
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div class="mb-3">
                              <h5 class="rExtraBold txt-azul-marino mb-0">
                                Ana Luisa
                              </h5>
                              <h5 class="rExtraBold txt-azul-marino mt-0">
                                Rodríguez
                              </h5>
                              <h5 class="rSemiBold txt-azul">
                                Préstamo de $5,000
                              </h5>
                            </div>
                            <div class="oRegular txt-gris">
                              <p>
                                Gracias al préstamo de $5,000 que adquirí con
                                Percapita pude regalarle un viaje a la playa a
                                toda mi familia, estuvimos muy contentos.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="card-review">
                      <div class="container">
                        <div class="row">
                          <div class="text-end">
                            <img
                              src={icono_percapita}
                              alt="PERCAPITA"
                              class="img-fluid icono-per-review"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 py-3">
                            <img
                              src={angelica_luis}
                              alt="PERCAPITA"
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div class="mb-3">
                              <h5 class="rExtraBold txt-azul-marino mb-0">
                                Angélica Luis
                              </h5>
                              <h5 class="rExtraBold txt-azul-marino mt-0">
                                De Velasco
                              </h5>
                              <h5 class="rSemiBold txt-azul">
                                Préstamo de $10,000
                              </h5>
                            </div>
                            <div class="oRegular txt-gris">
                              <p>
                              Gracias al préstamo de $10,000.00 que me dio Percapita pude invertir en mi pequeño negocio de comida, para generar más ingresos.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button aria-label="Previous" class="glider-prev">
                  <i class="fa-solid fa-chevron-left"></i>
                </button>
                <button aria-label="Next" class="glider-next">
                  <i class="fa-solid fa-chevron-right"></i>
                </button>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <Footer />
    </motion.div>
  );
};
