import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  HashRouter 
} from "react-router-dom";
import { Home } from './components/front/Home2'
import {FloatUser} from './components/float.component'
import rutasPublicas from "./utils/RutasPublicas";
import UserProvider from "./context/UserProvider";
import Pdf from "./components/portal/pagina/pdf/catycomisiones.pdf";
import PDFViewer from './components/portal/pagina/pdf/PdfViewer';


function App() {
  return (
    <div className="App">
      <UserProvider>
      <FloatUser />
            <Router>
              <Routes>
                <Route exact path="/" element={<Home />}></Route>
                {rutasPublicas.map(({ path, component }) => (
                  <Route path={path} element={component} />
                ))}
                <Route path="*" component={() => <Navigate to="/" />} />
                <Route path="/pdf" element={ <PDFViewer  />} />
              </Routes>             
            </Router>
            </UserProvider>
    </div>
  );
}

export default App;
