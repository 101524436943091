import React,{useEffect} from "react";
import { Footer } from "../../front/Footer";
import { Navbar } from "../../front/Navbar";
import icon_percapita from "../../../img/icon-percapita.png";
import educacion_1 from "../../../img/educacion-1.png";
import icono_calendario from "../../../img/icono-calendario.png";
import finanzas_2 from "../../../img/finanzas-2.png";
import finanzas_6 from "../../../img/finanzas-6.png";
import finanzas_4 from "../../../img/finanzas-4.png";
import educacion_2 from "../../../img/educacion-2.png";
import finanzas_3 from "../../../img/finanzas-3.png";
import finanzas_1 from "../../../img/finanzas-1.png";
import { useAnimation, useInView, motion } from "framer-motion";
import Post from "./posts/Post";
import Paginate from "./Paginate";
import { useNavigate } from "react-router-dom";
export const Blog = () => {
  const [items, setItems] = React.useState([]);
  const [itemsOriginal, setItemsOriginal] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(6);
  const [isChange, setIsChange] = React.useState(false);
  const [category, setCategory] = React.useState([]);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts =
    items.length > 0 ? items.slice(indexOfFirstPost, indexOfLastPost) : 0;
  const [orderOption, setOrderOption] = React.useState("desc");
  const navigate = useNavigate();
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const goToPage = (id) => {
    navigate(`/blogD/${id}`);
  };

  React.useEffect(() => {
    const data = async () => {
      await fetch("https://app.percapita.mx/api/blog-get-items", {
        method: "GET",
      })
        .then((data) => data.json())
        .then((data) => {
          setItems(data);
          setItemsOriginal(data);
          setCategory(getDistinctValuesByKey(data, "category"));
        });
    };
    data();
  }, []);
  
  useEffect(() => {
    document.title = "Percapita - Blog"
  },[])

  function getDistinctValuesByKey(arr, key) {
    return [...new Set(arr.map((item) => item.category))];
  }
  function orderList(array, order) {
    console.log(
      array.sort(function (a, b) {
        let p = new Date(a.datePost),
          q = new Date(b.datePost);
        return order === "asc" ? p - q : q - p;
      })
    );
    let data = array.sort(function (a, b) {
      let p = new Date(a.datePost),
        q = new Date(b.datePost);
      return order === "asc" ? p - q : q - p;
    });
    return setItems(data);
    //     array.sort(function (a, b) {
    //         let p = new Date(a.datePost), q = new Date(b.datePost);
    //         return ( order === 'asc' ? p - q : q - p)
    // })
    //)
  }

  function handleChangeParam(event) {
    setIsChange(!isChange);
    if (event.target.name === "category") {
      itemsOriginal.sort(function (a, b) {
        let p = new Date(a.datePost),
          q = new Date(b.datePost);
        return orderOption === "asc" ? p - q : q - p;
      });
      if (event.target.value === "Todas") {
        //alert(orderOption)
        setCurrentPage(1);
        return setItems(
          itemsOriginal.filter((item) => item.category !== undefined)
        );
      }
      setCurrentPage(1);
      return setItems(
        itemsOriginal.filter((item) => item.category === event.target.value)
      );
    }
    if (event.target.name === "order") {
      setOrderOption(event.target.value);
      setItems([]);
      let a = items;
      let b = a.sort(function (a, b) {
        let p = new Date(a.datePost),
          q = new Date(b.datePost);
        return event.target.value === "asc" ? p - q : q - p;
      });
      setCurrentPage(1);
      return setItems(b.filter((item) => item.category !== undefined));
      //setCurrentPage(1)
      // setCurrentPosts(items.slice(indexOfFirstPost, indexOfLastPost))
    }
  }
  const edicom = async () => {
    await fetch("https://app.percapita.mx/api/nomina-loan/test-edicom", {
      method: "GET",
    })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
      });
  };
  function ejecu() {
    edicom();
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <Navbar />
      {/* <button disaled class="btn btn-primary btn-conoce-azul" onClick={ejecu}>
                      <h6 class="rBlack txt-azul-marino pt-1">edicom</h6>
                      </button> */}
      <div class="bg-blog">
        <div class="container">
          <div class="row">
            <div class="text-center pt-2">
              <img
                src={icon_percapita}
                alt="PERCAPITA"
                class="img-fluid  icono-headers"
              />
              <h1 class="rBlack display-4 mt-0">Blog</h1>
            </div>
          </div>
        </div>
      </div>

      <div class="container py-5">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12 col-lg-1">
            <label for="exampleInputEmail1" class="form-label sol1-tittle">
              Ordenar
            </label>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-3">
            <div class="text-start">
              <select
                class="form-select text-center"
                aria-label="Default select example"
                name="order"
                onChange={handleChangeParam}
                value={orderOption}
              >
                <option selected value="desc">
                  Mas reciente
                </option>
                <option value="asc">Mas antiguo</option>
              </select>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-3"></div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-2">
            <label for="exampleInputEmail1" class="form-label sol1-tittle">
              Categorías
            </label>
          </div>
          <div class="col-12 col-sm-12 col-md-12 col-lg-3">
            <div class="text-end">
              <select
                class="form-select text-center"
                aria-label="Default select example"
                onChange={handleChangeParam}
                name="category"
              >
                <option selected value="Todas">
                  Todas
                </option>
                {category.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {items.length > 0 && (
          <>
            <div class="row py-4">
              <Post
                posts={currentPosts}
                goToPage={goToPage}
                isChange={isChange}
              />
            </div>
            <Paginate
              postsPerPage={postsPerPage}
              totalPosts={items.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </>
        )}

        {/* <div class="row">
                    <div class="text-end">
                        <nav aria-label="...">
                            <ul class="pagination justify-content-end">
                                <li class="page-item active" aria-current="page">
                                    <span class="page-link">1</span>
                                </li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link" href="#">4</a></li>
                                <li class="page-item"><a class="page-link" href="#">5</a></li>
                                <li class="page-item"><a class="page-link" href="#">6</a></li>
                                <li class="page-item"><a class="page-link" href="#">7</a></li>
                            </ul>
                        </nav>
                    </div>
                </div> */}
      </div>
      <Footer />
    </motion.div>
  );
};
