import React, { useEffect, useState, useRef } from "react";
import { Navbar } from "../../components/front/Navbar";
import { Footer } from "../../components/front/Footer";
import circulo_flecha from "../../img/circulo-flecha.png";
import solicitud_linea from "../../img/solicitud-linea.png";
import confirma_celular from "../../img/confirma-celular.png";
import sube_documentos from "../../img/sube-documentos.png";
import prestamo from "../../img/prestamo.png";
import familia_index from "../../img/familia-index.png";
import logo_azul from "../../img/logo-azul.png";
import facilidad from "../../img/facilidad.png";
import respaldo from "../../img/respaldo.png";
import servicio from "../../img/servicio.png";
import seguridad from "../../img/seguridad.png";
import lealtad from "../../img/lealtad.png";
import logo_pabs from "../../img/logo-pabs.png";
import logo_pabs2 from "../../img/logo_pabs.png";
import logo from "../../img/logo-bco.png";
import logo_balnco from "../../img/logo_blanco.png";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { useAnimation, useInView, motion } from "framer-motion";
//
import icono_percapita from "../../img/icono-percapita.png";
import icono_mujer from "../../img/icono_mujer.png";
import ana_luisa from "../../img/ana-luisa.png";
import angelica_luis from "../../img/angelica-luis.png";
//
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "800px",

  maxHeight: "500px",
  background: "radial-gradient(circle at top left, #005593, #36c9d0)",
  boxShadow: 29,
  overflowY: "auto",
};

export const Home = () => {
  const [param, setParam] = useState({
    minValue: 2000,
    maxValue: 20000,
    step: 1000,
  });
  const [open2, setOpen2] = React.useState(false);
  const handleClose = () => setOpen2(false);
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(7000);
  const [amountText, setAmountText] = useState(amount);
  const [plazoL, setPlazoL] = useState("quincenal");
  const [pQuincenal, setPQuincenal] = useState(true);
  const [pSemanal, setPSemanal] = useState(false);
  const [plazoM, setplazoM] = useState("6 meses");
  const [pago, setPago] = useState(840);
  const [fin, setFin] = useState(2000);
  const [table, setTable] = useState([
    { monto: 2000, quincenal: 420, semanal: 210, plazo: "3 meses" },
    { monto: 3000, quincenal: 630, semanal: 310, plazo: "3 meses" },
    { monto: 4000, quincenal: 840, semanal: 410, plazo: "3 meses" },
    { monto: 5000, quincenal: 600, semanal: 300, plazo: "6 meses" },
    { monto: 6000, quincenal: 720, semanal: 350, plazo: "6 meses" },
    { monto: 7000, quincenal: 840, semanal: 410, plazo: "6 meses" },
    { monto: 8000, quincenal: 960, semanal: 500, plazo: "6 meses" },
    { monto: 9000, quincenal: 1080, semanal: 550, plazo: "6 meses" },
    { monto: 10000, quincenal: 1200, semanal: 600, plazo: "6 meses" },
    { monto: 11000, quincenal: 1320, semanal: 650, plazo: "6 meses" },
    { monto: 12000, quincenal: 930, semanal: 450, plazo: "12 meses" },
    { monto: 13000, quincenal: 1000, semanal: 480, plazo: "12 meses" },
    { monto: 14000, quincenal: 1100, semanal: 600, plazo: "12 meses" },
    { monto: 15000, quincenal: 1170, semanal: 550, plazo: "12 meses" },
    { monto: 16000, quincenal: 1250, semanal: 580, plazo: "12 meses" },
    { monto: 17000, quincenal: 1320, semanal: 620, plazo: "12 meses" },
    { monto: 18000, quincenal: 1400, semanal: 650, plazo: "12 meses" },
    { monto: 19000, quincenal: 1480, semanal: 700, plazo: "12 meses" },
    { monto: 20000, quincenal: 1550, semanal: 720, plazo: "12 meses" },
  ]);
  const [valueRadio, setValueRadio] = React.useState("quincenal");

  const handleChangeRadio2 = (event) => {
    setValueRadio(event.target.value);
  };
  const ref = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const inView = useInView(ref);
  const inView2 = useInView(ref2);
  const inView3 = useInView(ref3);
  const inView4 = useInView(ref4);
  const inView5 = useInView(ref5);
  const inView6 = useInView(ref6);
  const animation = useAnimation();
  const animation2 = useAnimation();
  const animation3 = useAnimation();
  const animation4 = useAnimation();
  const animation5 = useAnimation();
  const animation6 = useAnimation();
  const animation7 = useAnimation();
  const animation11 = useAnimation();
  const animation12 = useAnimation();
  const animation13 = useAnimation();
  const animation14 = useAnimation();

  const animation8 = useAnimation();
  const animation9 = useAnimation();
  const animation10 = useAnimation();
  const animation15 = useAnimation();
  const animation16 = useAnimation();
  const animation17 = useAnimation();
  const animation18 = useAnimation();
  const animation19 = useAnimation();
  const animation20 = useAnimation();
  const animation21 = useAnimation();
  const animation22 = useAnimation();
  const animation23 = useAnimation();

  function handleChange(event) {
    setAmount(parseFloat(event.target.value));
    setAmountText(parseFloat(event.target.value));
    const filter = table.filter(
      (e) => e.monto === parseFloat(event.target.value)
    );
    plazoL == "semanal"
      ? setPago(filter[0].semanal)
      : setPago(filter[0].quincenal);
    setplazoM(filter[0].plazo);
  }
  function handleChangeRadio(event) {
    console.log(event.target.value);
    setPlazoL(event.target.value);
    const filter = table.filter((e) => e.monto === amount);
    event.target.value == "semanal"
      ? setPago(filter[0].semanal)
      : setPago(filter[0].quincenal);
    setplazoM(filter[0].plazo);
  }

  function next(event) {
    let limit = param.maxValue;
    let a = amount + 1000;
    if (a <= limit) {
      setAmountText(a);
      setAmount(a);
      const filter = table.filter((e) => e.monto === a);
      plazoL == "semanal"
        ? setPago(filter[0].semanal)
        : setPago(filter[0].quincenal);
      setplazoM(filter[0].plazo);
    }
  }

  function previous(event) {
    let limit = param.minValue;
    let a = amount - 1000;
    if (a >= limit) {
      setAmountText(a);
      setAmount(a);
      const filter = table.filter((e) => e.monto === a);
      plazoL == "semanal"
        ? setPago(filter[0].semanal)
        : setPago(filter[0].quincenal);
      setplazoM(filter[0].plazo);
    }
  }
  useEffect(() => {
    if (inView) {
      console.log(1);
      animation.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
      animation2.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2 },
      });
      animation3.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.6 },
      });
    }
    if (!inView) {
      animation.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation2.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation3.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
    if (inView2) {
      console.log("inView3");
      animation11.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.6 },
      });
      animation12.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1 },
      });
      animation13.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.4 },
      });
      animation14.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.8 },
      });
    }
    if (!inView2) {
      animation11.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation12.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation13.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation14.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }

    if (inView3) {
      console.log(1);
      animation5.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
      animation6.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2 },
      });
      animation7.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.6 },
      });
    }
    if (!inView3) {
      animation5.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation6.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation7.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }

    if (inView4) {
      console.log(1);
      animation8.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.3 },
      });
      animation9.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.7 },
      });
      animation10.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.1 },
      });
      animation15.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.5 },
      });
      animation16.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.9 },
      });
      animation17.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 2.3 },
      });
    }
    if (!inView4) {
      animation8.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation9.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation10.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation15.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation16.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation17.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
    if (inView5) {
      animation18.start({
        x: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1 },
      });
    }
    if (!inView5) {
      animation18.start({
        x: "10%",
        opacity: 0,
        scale: 0.5,
      });
    }
    if (inView6) {
      console.log(1);
      animation19.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 0.8 },
      });
      animation20.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.2 },
      });
      animation21.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.6 },
      });
      animation22.start({
        y: 0,
        opacity: 1,
        scale: 1,
        transition: { ease: "easeInOut", duration: 1.6 },
      });
    }
    if (!inView6) {
      animation19.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation20.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation21.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
      animation22.start({
        y: "50%",
        opacity: 0,
        scale: 0.5,
      });
    }
  }, [inView, inView2, inView3, inView4, inView5, inView6]);

  useEffect(() => {
    console.log(amount);
    animation23.mount();
    animation23.start({
      y: "50%",
      opacity: 0,
      scale: 0.5,
    });
  }, [amount]);
  // useEffect(() => {
  //   setOpen2(true)
  // },[])
  return (
    <div>
      <Navbar />
      <Modal
        keepMounted
        open={open2}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* //<ScrollView width="100%" height="100%"> */}
        <Grid container spacing={2} sx={style}>
          <div className="modal-content">
            <div className="d-flex justify-content-end p-1  ">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-flex justify-content-center mb-3">
                <img
                  src={logo_balnco}
                  alt="PRADO'S"
                  width="220"
                  className="img-fluid"
                />
              </div>
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 p-3" style={{ background: "#ff00006E" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={logo_pabs2}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Tengo título PABS{" "}
                    </h4>
                    <h5 className="rLight">
                      Tu crédito ya está <br />
                      Pre-autorizado
                    </h5>

                    <div className="">
                      <a
                        href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                        className="btn btn-primary btn-conoce-azul btn-small"
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                    <div className="mt-4">
                      <p className="rLight mt-4">
                        ¿Qué es un Título PABS? <br />
                        <a
                          href="https://www.pabsmr.org/que-es-un-titulo-pabs/"
                          target="_blank"
                          style={{ color: "white" }}
                        >
                          Click aqui
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-sm-6 col-md-6 col-lg-6 p-3" style={{ background: "#ffff0040" }}>
                  <div className="d-flex justify-content-center">
                    <img
                      src={icono_mujer}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      className="img-fluid "
                    />
                  </div>
                  <div className="text-center text-white mt-3">
                    <h4 className="rBlack">
                      Quiero un <br /> crédito personal{" "}
                    </h4>
                    <h5 className="rLight">
                      Tu crédito en <br />
                      48 horas*
                    </h5>

                    <div className="">
                      <a
                        href="https://app.percapita.mx/Personal?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                        className="btn btn-primary btn-conoce-azul btn-small"
                      >
                        <h6 className="rBlack txt-azul-marino pt-1">
                          Solicitar
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Modal>
      {/* <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="d-flex justify-content-end p-2  ">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-center mb-5">
                <img src={logo} alt="PRADO'S" width="220" class="img-fluid" />
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div class="d-flex justify-content-center">
                    <img
                      src={logo_pabs2}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      class="img-fluid "
                    />
                  </div>
                  <div class="text-center text-white mt-3">
                    <h4 class="rBlack">
                      ¿Cuentas con un <br /> título PABS?{" "}
                    </h4>
                    <h5 class="rLight mt-4">
                      Tu crédito ya está <br />
                      Pre-autorizado
                    </h5>

                    <div class="mt-4">
                      <a
                        href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                        class="btn btn-primary btn-conoce-azul"
                      >
                        <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                      </a>
                    </div>
                    <div class="mt-4">
                      <p class="rLight mt-4">
                        ¿Qué es un Título PABS? <br />
                        <a
                          href="https://www.pabsmr.org/que-es-un-titulo-pabs/"
                          target="_blank"
                          style={{ color: "white" }}
                        >
                          Click aqui
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                  <div class="d-flex justify-content-center">
                    <img
                      src={icono_mujer}
                      alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                      width="80"
                      class="img-fluid "
                    />
                  </div>
                  <div class="text-center text-white mt-3">
                    <h4 class="rBlack">
                      Quiero un <br /> crédito personal{" "}
                    </h4>
                    <h5 class="rLight mt-4">
                      Tu crédito en <br />
                      48 horas*
                    </h5>

                    <div class="mt-4">
                      <a
                        href="https://app.percapita.mx/Personal?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                        class="btn btn-primary btn-conoce-azul"
                      >
                        <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div class="d-none d-sm-none d-md-block">
        <div class="bg-index">
          <div class="container" ref={ref}>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-10">
                <div class="row">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                    <div class="txt-index">
                      <div className="mb-2">
                        <motion.div
                          ////initial={{ y:'50%', opacity:0, scale: 0.5 }}
                          animate={animation}
                          //////exit={{y: "50%", transition:{duration:1.2}}}
                          //transition={{ ease: "easeInOut", duration: 5 }}
                        >
                          <h1 className="rBlack txt- display-2 mb-4 txt-azul">Adquiere </h1>
                        </motion.div>
                        <motion.div
                          //initial={{ y:'50%', opacity:0, scale: 0.5 }}
                          animate={animation2}
                          ////exit={{y: "50%", transition:{duration:1.2}}}
                          //transition={{ ease: "easeInOut", duration: 1.2 }}
                        >
                          <h1 className="rBlack display-2  mb-4 txt-azul">libertad</h1>
                        </motion.div>
                        <motion.div
                          //initial={{ y:'50%', opacity:0, scale: 0.5 }}
                          animate={animation3}
                          //exit={{y: "50%", transition:{duration:1.6}}}
                          //transition={{ ease: "easeInOut", duration: 1.6 }}
                        >
                          <h1 className="rBlack txt-azul display-2 mb-4">
                            económica
                          </h1>
                        </motion.div>
                      </div>
                      <motion.div
                        //initial={{ y:'50%', opacity:0, scale: 0.5 }}
                        animate={animation4}
                        //exit={{y: "50%", transition:{duration:2}}}
                        //transition={{ ease: "easeInOut", duration: 2 }}
                      >
                        {/* <h2 className="rBold">
                          <img
                            src={circulo_flecha}
                            alt="PERCAPITA"
                            className="img-fluid mb-2"
                          />
                          En 48 horas*
                        </h2> */}
                      </motion.div>
                    </div>
                  </div>
                  <motion.div
                    //initial={{ y:'50%', opacity:0, scale: 0.5 }}
                    animate={animation}
                    //exit={{y: "50%", transition:{duration:1}}}
                    transition={{ ease: "easeOut", duration: 1 }}
                    class="col-12 col-sm-12 col-md-12 col-lg-6"
                  >
                    {/*<div class="mx-3">
                      <div class="bg-cuadro-bco">
                        <div class="margen-cuadro py-5">
                          <h2 class="rExtraBold txt-azul-marino">
                            ¿Con <b class="txt-azul">cuantó dinero</b>
                          </h2>
                          <h2 class="rExtraBold txt-azul-marino">
                            podemos apoyarte?
                          </h2>
                          <div class="my-3 justify-content-center pago-select">
                            <div class="text-center me-4 ">
                              <button class="btn btn-primary btn-minus">
                                <i
                                  class="fa-solid fa-minus"
                                  onClick={previous}
                                ></i>
                              </button>
                            </div>
                            <motion.div
                              animation={animation23}
                              class="text-center ms-4"
                            >
                              <h2 class="oExtraBold txt-azul-marino">
                                {amountText.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: "USD",
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </h2>
                            </motion.div>
                            <div class="text-center ms-4">
                              <button class="btn btn-primary btn-plus">
                                <i class="fa-solid fa-plus" onClick={next}></i>
                              </button>
                            </div>
                          </div>
                          <div class="slidecontainer pb-2">
                            <input
                              type="range"
                              className="slider"
                              min={param.minValue}
                              max={param.maxValue}
                              step={param.step}
                              value={amount}
                              id="customRange1"
                              onChange={handleChange}
                            />
                          </div>
                          <div class="my-4">
                            <div class="pago-aprox">
                              <div class="mx-3 py-4">
                                <div class="text-center mb-4">
                                  <h6 class="txt-azul-marino rExtraBold h5">
                                    Tu pago aproximado:
                                  </h6>
                                </div>
                                <div class=" mb-4">
                                  <div class="me-5">
                                    {/* <div class="form-check">
                                      <input 
                                        class="form-check-input" 
                                        type="radio" 
                                        name="radioPlazo" 
                                        id="radioQuincenal" 
                                        value="quincenal" 
                                        checked={plazoL === "quincenal"} 
                                        onChange={handleChangeRadio
                                        }/>
                                      <label class="form-check-label txt-gris oBold" for="radioQuincenal">
                                        Quincenal
                                      </label>
                                    </div>
                                    <div class="form-check">
                                      <input 
                                        class="form-check-input" 
                                        type="radio" 
                                        name="radioPlazo" 
                                        id="radioSemanal" 
                                        value="semanal" 
                                        checked={plazoL === "semanal"} 
                                        onChange={handleChangeRadio} 
                                        />
                                      <label class="form-check-label txt-gris oBold" for="radioSemanal">
                                        Semanal
                                      </label>
                                    </div> */}
                                    {/*<FormControl>
                                      <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={plazoL}
                                        onChange={handleChangeRadio}
                                      >
                                        <FormControlLabel
                                          sx={{
                                            color: "black",
                                          }}
                                          value="quincenal"
                                          control={<Radio />}
                                          label="Quincenal"
                                        />
                                        <FormControlLabel
                                          sx={{
                                            color: "black",
                                          }}
                                          value="semanal"
                                          control={<Radio />}
                                          label="Semanal"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                  <div class="ms-2">
                                    <h2 class="oExtraBold txt-azul">
                                      {pago.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                    </h2>
                                  </div>
                                </div>
                                <div class="my-4 mx-3">
                                  <p class="rLight txt-gris">
                                    El pago estimado es para fines informativos
                                    y de comparación,
                                  </p>
                                  <p class="rLight txt-gris">
                                    este podrá cambiar en base al proceso de
                                    evaluación de
                                  </p>
                                  <p class="rLight txt-gris">
                                    nuestra área de crédito.
                                  </p>
                                  <p class="rLight txt-gris">
                                  Consulta CAT y comisiones en:
                                  </p>
                                  <p class="rLight txt-gris">
                                  <a className="link-cat" href="https://percapita.mx/catycomisiones/catycomisiones.pdf">https://percapita.mx/catycomisiones/catycomisiones.pdf</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3 text-center">
                            {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </a> */}
                            {/*<a
                              href="#"
                              class="btn btn-primary btn-conoce-azul"
                              onClick={() => setOpen2(true)}
                            >
                              <h6 class="rBlack txt-azul-marino pt-1">
                                Solicitar
                              </h6>
                            </a>
                            {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
                          {/*</div>
                        </div>
                      </div>
                    </div>*/}
                    <div class="mx-3">
                      <div class="pt-5">
                        <div class="margen-cuadro py-5">
                          <div class="mt-3 text-center py-5">
                            {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </a> */}
                            <a
                              href="#"
                              class="btn btn-primary btn-conoce-azul"
                              onClick={() => setOpen2(true)}
                            >
                              <h6 class="rBlack txt-azul-marino pt-1">
                                Solicitar
                              </h6>
                            </a>
                            {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="bg-index-phone py-5">
          <div class="container">
            <div class="row">
              <div class="text-center">
                <div class="mb-5">
                  <h1 class="rLight display-2">
                    Adquiere <b class="rBlack">libertad</b>
                  </h1>
                  <h1 class="rBlack txt-azul display-2">económica</h1>
                </div>
                <div class="text-center pt-3">
                  <img
                    src={circulo_flecha}
                    alt="PERCAPITA"
                    class="img-fluid mb-2"
                  />
                </div>
                <h2 class="rBold">En 48 horas*</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gris">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <div class="mx-1">
                  {/*<div class="bg-cuadro-bco">
                    <div class="margen-cuadro py-5">
                      <h2 class="rExtraBold txt-azul-marino">
                        ¿Con <b class="txt-azul">cuantó dinero</b>
                      </h2>
                      <h2 class="rExtraBold txt-azul-marino">
                        podemos apoyarte?
                      </h2>
                      <div class="my-3 d-flex justify-content-center">
                        <div class="text-center me-4 ">
                          <button class="btn btn-primary btn-minus">
                            <i class="fa-solid fa-minus" onClick={previous}></i>
                          </button>
                        </div>
                        <div class="text-center ms-2">
                          <h2 class="oExtraBold txt-azul-marino">
                            {amountText.toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </h2>
                        </div>
                        <div class="text-center ms-4">
                          <button class="btn btn-primary btn-plus">
                            <i class="fa-solid fa-plus" onClick={next}></i>
                          </button>
                        </div>
                      </div>
                      <div class="slidecontainer pb-2">
                        <input
                          type="range"
                          className="slider"
                          min={param.minValue}
                          max={param.maxValue}
                          step={param.step}
                          value={amount}
                          id="customRange1"
                          onChange={handleChange}
                        />
                      </div>
                      <div class="my-4">
                        <div class="">
                          <div class=" py-4">
                            <div class="text-center mb-4">
                              <h6 class="txt-azul-marino rExtraBold h5">
                                Tu pago aproximado:
                              </h6>
                            </div>
                            <div class="pago-select mb-4">
                              <div class="me-5">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="radioPlazo"
                                    id="radioQuincenal"
                                    value="quincenal"
                                    checked={plazoL === "quincenal"}
                                    onChange={handleChangeRadio}
                                  />
                                  <label
                                    class="form-check-label txt-gris oBold"
                                    for="radioQuincenal"
                                  >
                                    Quincenal
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="radioPlazo"
                                    id="radioSemanal"
                                    value="semanal"
                                    checked={plazoL === "semanal"}
                                    onChange={handleChangeRadio}
                                  />
                                  <label
                                    class="form-check-label txt-gris oBold"
                                    for="radioSemanal"
                                  >
                                    Semanal
                                  </label>
                                </div>
                              </div>
                              <div class="ms-2">
                                <h2 class="oExtraBold txt-azul">
                                  {pago.toLocaleString("en-US", {
                                    style: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </h2>
                              </div>
                            </div>
                            <div class="mt-4">
                              <p class="rLight txt-gris">
                                El pago estimado es para fines informativos y de
                                comparación, este podrá cambiar en base al
                                proceso de evaluación de nuestra área de
                                crédito.
                              </p>
                                  <p class="rLight txt-gris">
                                  Consulta CAT y comisiones en: 
                                  </p>
                                  <p class="rLight txt-gris">
                                  <a className="link-cat" href="https://percapita.mx/catycomisiones/catycomisiones.pdf">https://percapita.mx/catycomisiones/catycomisiones.pdf</a>
                                  </p>
                            </div>
                          </div>
                          <div class=" text-center">
                            {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </a> */}
                            {/*<a
                              href="#"
                              onClick={() => setOpen2(true)}
                              class="btn btn-primary btn-conoce-azul"
                            >
                              <h6 class="rBlack txt-azul-marino pt-1">
                                Solicitar
                              </h6>
                            </a>
                            {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
                          {/*</div>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                  <div class="">
                    <div class="margen-cuadro py-5">
                      <div class="my-4">
                        <div class="">
                          <div class=" text-center">
                            {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </a> */}
                            <a
                              href="#"
                              onClick={() => setOpen2(true)}
                              class="btn btn-primary btn-conoce-azul"
                            >
                              <h6 class="rBlack txt-azul-marino pt-1">
                                Solicitar
                              </h6>
                            </a>
                            {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-none d-sm-none d-md-block">
        <div class="container-fluid p-0">
          <div class="row py-4">
            <div class="col-12 col-sm-12 col-md-12 col-lg-2 pt-5">
              {/* <div disabled class="bg-solicitar-cred py-2"> */}
              {/* <a href="https://app.percapita.mx/?from=Personal" class="nav-link text-white">
                  <h6>Solicitar crédito</h6>
                </a> */}
              {/* <button disabled class="btn btn-primary btn-solicitar"> 
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                             </button> */}
              {/* </div> */}
              {/* <button disabled class="btn btn-primary btn-solicitar"> 
               <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                </button> */}
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-2"></div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4">
              <div class="text-center">
                <h2 class="txt-azul-marino rLight mb-0">Sólo sigue </h2>
                <h1 class="txt-azul-marino rExtraBold mt-0">
                  4 sencillos <b class="txt-azul">pasos</b>
                </h1>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-4"></div>
          </div>
          <div class="container py-3" ref={ref2}>
            <div class="row mb-5">
              <motion.div
                animate={animation11}
                class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
              >
                <div class="mx-3">
                  <div class="bg-cards py-3">
                    <div class="pt-3">
                      <img
                        src={solicitud_linea}
                        alt="SOLICITUD EN LÍNEA"
                        class="img-fluid mb-3 icono-solicitud"
                      />
                      <p class="txt-azul txt-paso rRegular">Paso 01</p>
                    </div>
                    <div class="mb-3">
                      <h6 class="txt-azul-marino h5 rExtraBold">Solicitud</h6>
                      <h6 class="txt-azul-marino h5 rExtraBold">en línea</h6>
                    </div>
                    <div class="rRegular ">
                      <p class="txt-gris">Llena el formulario en </p>
                      <p class="txt-gris">línea con tu </p>
                      <p class="txt-gris">información personal.</p>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                animate={animation12}
                class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
              >
                <div class="mx-3">
                  <div class="bg-cards py-3">
                    <div class="pt-3">
                      <img
                        src={confirma_celular}
                        alt="CONFIRMA TU CELULAR"
                        class="img-fluid mb-3 icono-celular"
                      />
                      <p class="txt-azul txt-paso rRegular">Paso 02</p>
                    </div>
                    <div class="mb-3">
                      <h6 class="txt-azul-marino h5 rExtraBold">Confirma</h6>
                      <h6 class="txt-azul-marino h5 rExtraBold">tu celular</h6>
                    </div>
                    <div class="rRegular ">
                      <p class="txt-gris">Confirma tu cuenta </p>
                      <p class="txt-gris">con el código que </p>
                      <p class="txt-gris">recibirás en tu celular.</p>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                animate={animation13}
                class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
              >
                <div class="mx-3">
                  <div class="bg-cards py-3">
                    <div class="pt-3">
                      <img
                        src={sube_documentos}
                        alt="SUBE TUS DOCUMENTOS"
                        class="img-fluid mb-3 icono-documentos"
                      />
                      <p class="txt-azul txt-paso rRegular">Paso 03</p>
                    </div>
                    <div class="mb-3">
                      <h6 class="txt-azul-marino h5 rExtraBold">Subes tus</h6>
                      <h6 class="txt-azul-marino h5 rExtraBold">documentos</h6>
                    </div>
                    <div class="rRegular ">
                      <p class="txt-gris">Identificación oficial y</p>
                      <p class="txt-gris">un comprobante de</p>
                      <p class="txt-gris">domicilio.</p>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                animate={animation14}
                class="col-12 col-sm-12 col-md-12 col-lg-3 mb-3"
              >
                <div class="mx-3">
                  <div class="bg-cards py-3">
                    <div class="pt-3">
                      <img
                        src={prestamo}
                        alt="TU PRESTAMO EN 48 HRS."
                        class="img-fluid mb-3 icono-prestamo"
                      />
                      <p class="txt-azul txt-paso rRegular">Paso 04</p>
                    </div>
                    <div class="mb-3">
                      <h6 class="txt-azul-marino h5 rExtraBold">Tu préstamo</h6>
                      <h6 class="txt-azul-marino h5 rExtraBold">en 48 hrs*</h6>
                    </div>
                    <div class="rRegular ">
                      <p class="txt-gris">Tu dinero estará listo </p>
                      <p class="txt-gris">En 48 horas*.</p>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
            <div class="row">
              <div class="text-center">
                {/* <a href="https://app.percapita.mx/?from=Personal"  class="btn btn-primary btn-solicitar">
                  <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                </a> */}
                <a
                  href="#"
                  onClick={() => setOpen2(true)}
                  class="btn btn-primary btn-conoce-azul"
                >
                  <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                </a>
                {/* <button disabled class="btn btn-primary btn-solicitar">
                              <h6 class="pt-1 rBlack">Solicitar crédito</h6>
                            </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="container py-5">
          <div class="row">
            <div class="text-center mb-2">
              <h2 class="txt-azul-marino rLight display-3">Sólo sigue</h2>
              <h1 class="txt-azul-marino rExtraBold display-3">
                4 sencillos <b class="txt-azul">pasos</b>
              </h1>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              <div class="mx-2 mb-4">
                <div class="bg-cards py-3">
                  <div class="row justify-content-center">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 pt-3">
                      <div class="text-start ms-4">
                        <p class="txt-azul txt-paso rRegular">Paso 01</p>
                      </div>
                      <div class="ms-4">
                        <img
                          src={solicitud_linea}
                          alt="SOLICITUD EN LÍNEA"
                          class="img-fluid mb-3 icono-solicitud"
                        />
                      </div>
                    </div>
                    <div class="col-8 col-sm-8 col-md-8 col-lg-8">
                      <div class="ms-2">
                        <div class="mb-3 text-start">
                          <h6 class="txt-azul-marino h2 rExtraBold mb-0">
                            Solicitud
                          </h6>
                          <h6 class="txt-azul-marino h2 rExtraBold">
                            en línea
                          </h6>
                        </div>
                        <div class="rRegular text-start">
                          <p class="txt-gris">
                            Llena el formulario <br /> en línea con tu
                            información <br /> personal.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              <div class="mx-2 mb-4">
                <div class="bg-cards py-3">
                  <div class="row justify-content-center">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 pt-2">
                      <div class="text-start ms-4">
                        <p class="txt-azul txt-paso rRegular">Paso 02</p>
                      </div>
                      <div class="ms-4">
                        <img
                          src={confirma_celular}
                          alt="CONFIRMA TU CELULAR"
                          class="img-fluid mb-3 icono-celular"
                        />
                      </div>
                    </div>
                    <div class="col-8 col-sm-8 col-md-8 col-lg-8">
                      <div class="ms-2">
                        <div class="mb-3 text-start">
                          <h6 class="txt-azul-marino h2 rExtraBold mb-0">
                            Confirma tu
                          </h6>
                          <h6 class="txt-azul-marino h2 rExtraBold">celular</h6>
                        </div>
                        <div class="rRegular text-start">
                          <p class="txt-gris">
                            Confirma tu cuenta
                            <br /> con el código que <br /> recibirás en tu{" "}
                            <br /> celular.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              <div class="mx-2 mb-4">
                <div class="bg-cards py-3">
                  <div class="row justify-content-center">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 pt-2">
                      <div class="text-start ms-4">
                        <p class="txt-azul txt-paso rRegular">Paso 03</p>
                      </div>
                      <div class="ms-4">
                        <img
                          src={sube_documentos}
                          alt="SUBE TUS DOCUMENTOS"
                          class="img-fluid mb-3 icono-documentos"
                        />
                      </div>
                    </div>
                    <div class="col-8 col-sm-8 col-md-8 col-lg-8">
                      <div class="ms-2">
                        <div class="mb-3 text-start">
                          <h6 class="txt-azul-marino h2 rExtraBold mb-0">
                            Sube tus
                          </h6>
                          <h6 class="txt-azul-marino h2 rExtraBold mt-0">
                            documentos
                          </h6>
                        </div>
                        <div class="rRegular text-start">
                          <p class="txt-gris">
                            Sube tus
                            <br /> documentos
                            <br /> oficiales que se <br /> solicitan.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
              <div class="mx-2 mb-4">
                <div class="bg-cards py-3">
                  <div class="row justify-content-center">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4 pt-3">
                      <div class="text-start ms-4">
                        <p class="txt-azul txt-paso rRegular">Paso 04</p>
                      </div>
                      <div class="ms-4">
                        <img
                          src={prestamo}
                          alt="TU PRESTAMO EN 48 HRS."
                          class="img-fluid mb-3 icono-prestamo"
                        />
                      </div>
                    </div>
                    <div class="col-8 col-sm-8 col-md-8 col-lg-8">
                      <div class="ms-2">
                        <div class="mb-3 text-start">
                          <h6 class="txt-azul-marino h2 rExtraBold mb-0">
                            Tu préstamo
                          </h6>
                          <h6 class="txt-azul-marino h2 rExtraBold mt-0">
                            en 48 hrs*
                          </h6>
                        </div>
                        <div class="rRegular text-start">
                          <p class="txt-gris">
                            Confirma tu cuenta
                            <br /> con el código que
                            <br /> recibirás en tu <br /> celular.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-none d-sm-none d-md-block">
        <div class="bg-familia-index">
          <div class="container pt-5">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                <div class="txt-libertad">
                  <motion.div animate={animation5}>
                    <h2 class="rLight h3">Queremos que tengas</h2>
                  </motion.div>

                  <motion.div animate={animation6}>
                    <h1 class="rBlack mt-1">La libertad</h1>
                  </motion.div>

                  <motion.div animate={animation7}>
                    <h1 class="rBlack mt-2">
                      económica{" "}
                      <h1 class="txt-azul mt-2">para ti y tu familia</h1>
                    </h1>
                  </motion.div>
                </div>
                <motion.div animate={animation7} class="mt-4">
                  {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-conoce-azul">
                    <h6 class="rBlack txt-azul-marino pt-1">Solicitar crédito</h6>
                  </a> */}
                  <a
                    href="#"
                    onClick={() => setOpen2(true)}
                    class="btn btn-primary btn-conoce-azul"
                  >
                    <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                  </a>
                  {/* <button disabled class="btn btn-primary btn-solicitar btn-conoce-azul">
                              <h6 class="rBlack txt-azul-marino pt-1">Solicitar crédito</h6>
                            </button> */}
                </motion.div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-7" ref={ref3}>
                <div class="text-center">
                  <img src={familia_index} alt="PERCAPITA" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="bg-familia-index-phone">
          <div class="container py-4">
            <div class="row">
              <div class="txt-libertad text-center">
                <h2 class="rLight h3">Queremos que tengas</h2>
                <h1 class="rBlack">libertad económica</h1>
                <h1 class="rBlack txt-azul">para ti y tu familia</h1>
              </div>
              <div class="text-center">
                <img src={familia_index} alt="PERCAPITA" class="img-fluid" />
              </div>
              <div class="mt-4 text-center">
                {/* <a href="https://app.percapita.mx/?from=Personal" class="btn btn-primary btn-conoce-azul">
                  <h6 class="rBlack txt-azul-marino pt-1">Solicitar crédito</h6>
                </a> */}
                {/* <button disabled class="btn btn-primary btn-conoce-azul">
                              <h6 class="rBlack txt-azul-marino pt-1">Solicitar crédito</h6>
                            </button> */}
                <a
                  href="#"
                  onClick={() => setOpen2(true)}
                  class="btn btn-primary btn-conoce-azul"
                >
                  <h6 class="rBlack txt-azul-marino pt-1">Solicitar</h6>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-none d-sm-none d-md-block">
        <div class="container py-5">
          <div class="row" ref={ref4}>
            <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-10 ">
              <div class="text-center">
                <h3 class="txt-azul-marino rLight">
                  Beneficios de tener tu <b class="rBlack">préstamo con </b>
                </h3>
                <img src={logo_azul} alt="PERCAPITA" class="img-fluid" />
              </div>
              <div class="row pt-5">
                <motion.div
                  animate={animation8}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-1">
                    <div class="text-center">
                      <img
                        src={facilidad}
                        alt="FACILIDAD"
                        class="img-fluid icono-facilidad"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios">
                    <h3 class="txt-azul-marino rExtraBold h4">Facilidad</h3>
                    <p class="txt-gris rRegular">Sin trámites burtocráticos</p>
                    <p class="txt-gris rRegular">o complicados.</p>
                  </div>
                </motion.div>
                <motion.div
                  animate={animation9}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-2">
                    <div class="text-center">
                      <img
                        src={respaldo}
                        alt="RESPALDO"
                        class="img-fluid icono-respaldo"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios">
                    <h3 class="txt-azul-marino rExtraBold h4">Respaldo</h3>
                    <p class="txt-gris rRegular">Empresa legalmente</p>
                    <p class="txt-gris rRegular">constituida.</p>
                  </div>
                </motion.div>
                <motion.div
                  animate={animation10}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-1">
                    <div class="text-center">
                      <img
                        src={servicio}
                        alt="SERVICIO"
                        class="img-fluid icono-facilidad"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios">
                    <h3 class="txt-azul-marino rExtraBold h4">Servicio</h3>
                    <p class="txt-gris rRegular">Autorizaciones en </p>
                    <p class="txt-gris rRegular">minutos.</p>
                  </div>
                </motion.div>
              </div>
              <div class="row pt-5">
                <motion.div
                  animate={animation15}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-2">
                    <div class="text-center">
                      <img
                        src={seguridad}
                        alt="SEGURIDAD"
                        class="img-fluid icono-respaldo"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios">
                    <h3 class="txt-azul-marino rExtraBold h4">Seguridad</h3>
                    <p class="txt-gris rRegular">Cobranza a domicilio</p>
                  </div>
                </motion.div>
                <motion.div
                  animate={animation16}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-1">
                    <div class="text-center">
                      <img
                        src={facilidad}
                        alt="CONFIANZA"
                        class="img-fluid icono-facilidad"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios">
                    <h3 class="txt-azul-marino rExtraBold h4">Confianza</h3>
                    <p class="txt-gris rRegular">No tenemos cobros </p>
                    <p class="txt-gris rRegular">ocultos.</p>
                  </div>
                </motion.div>
                <motion.div
                  animate={animation17}
                  class="col-12 col-sm-12 col-md-12 col-lg-4"
                >
                  <div class="bg-cuadro-2">
                    <div class="text-center">
                      <img
                        src={lealtad}
                        alt="LEALTAD"
                        class="img-fluid icono-respaldo"
                      />
                    </div>
                  </div>
                  <div class="txt-beneficios">
                    <h3 class="txt-azul-marino rExtraBold h4">Lealtad</h3>
                    <p class="txt-gris rRegular">Aportaciones</p>
                    <p class="txt-gris rRegular">congeladas.</p>
                  </div>
                </motion.div>
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-1"></div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="container py-5">
          <div class="row">
            <div class="text-center">
              <h3 class="txt-azul-marino rLight">
                Beneficios de tener tu <b class="rBlack">préstamo con </b>
              </h3>
              <img src={logo_azul} alt="PERCAPITA" class="img-fluid" />
            </div>
            <div class="row pt-5">
              <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                <div class="ms-4">
                  <div class="bg-cuadro-1">
                    <div class="text-center">
                      <img
                        src={facilidad}
                        alt="FACILIDAD"
                        class="img-fluid icono-facilidad"
                      />
                    </div>
                  </div>
                </div>
                <div class="txt-beneficios text-center">
                  <h3 class="txt-azul-marino rExtraBold h4">Facilidad</h3>
                  <p class="txt-gris rRegular">
                    Sin trámites <br /> burtocráticos o <br /> complicados.
                  </p>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                <div class="ms-4">
                  <div class="bg-cuadro-2">
                    <div class="text-center">
                      <img
                        src={seguridad}
                        alt="SEGURIDAD"
                        class="img-fluid icono-respaldo"
                      />
                    </div>
                  </div>
                </div>
                <div class="txt-beneficios text-center">
                  <h3 class="txt-azul-marino rExtraBold h4">Seguridad</h3>
                  <p class="txt-gris rRegular">Cobranza a domicilio</p>
                </div>
              </div>
            </div>
            <div class="row py-2">
              <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                <div class="ms-4">
                  <div class="bg-cuadro-1">
                    <div class="text-center">
                      <img
                        src={facilidad}
                        alt="CONFIANZA"
                        class="img-fluid icono-facilidad"
                      />
                    </div>
                  </div>
                </div>
                <div class="txt-beneficios text-center">
                  <h3 class="txt-azul-marino rExtraBold h4">Confianza</h3>
                  <p class="txt-gris rRegular">
                    No tenemos <br /> cobros ocultos.
                  </p>
                </div>
              </div>

              <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                <div class="ms-4">
                  <div class="bg-cuadro-2">
                    <div class="text-center">
                      <img
                        src={respaldo}
                        alt="RESPALDO"
                        class="img-fluid icono-respaldo"
                      />
                    </div>
                  </div>
                </div>

                <div class="txt-beneficios text-center">
                  <h3 class="txt-azul-marino rExtraBold h4">Respaldo</h3>
                  <p class="txt-gris rRegular">
                    Empresa legalmente <br /> constituida.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                <div class="ms-4">
                  <div class="bg-cuadro-1">
                    <div class="text-center">
                      <img
                        src={servicio}
                        alt="SERVICIO"
                        class="img-fluid icono-facilidad"
                      />
                    </div>
                  </div>
                </div>
                <div class="txt-beneficios text-center">
                  <h3 class="txt-azul-marino rExtraBold h4">Servicio</h3>
                  <p class="txt-gris rRegular">
                    Autorizaciones en <br /> minutos.
                  </p>
                </div>
              </div>
              <div class="col-6 col-sm-6 col-md-6 col-lg-6">
                <div class="ms-4">
                  <div class="bg-cuadro-2">
                    <div class="text-center">
                      <img
                        src={lealtad}
                        alt="LEALTAD"
                        class="img-fluid icono-respaldo"
                      />
                    </div>
                  </div>
                </div>
                <div class="txt-beneficios text-center">
                  <h3 class="txt-azul-marino rExtraBold h4">Lealtad</h3>
                  <p class="txt-gris rRegular">
                    Aportaciones <br /> congeladas.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-none d-sm-none d-md-block" ref={ref5}>
        <div class="bg-pabs">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-7"></div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                <div class="mx-5">
                  <motion.div animate={animation18} class="bg-cuadro-azul">
                    <div class="mx-3 py-5">
                      <div class="mb-3">
                        <h3 class="rBlack">Tengo título PABS</h3>
                      </div>
                      <div class="text-center my-5">
                        <img
                          src={logo_pabs}
                          alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                          class="img-fluid logo-pabs"
                        />
                      </div>
                      <div class="">
                        <h3 class="rLight">
                          ¡Ya tienes tu <b class="rBlack txt-azul">crédito</b>
                        </h3>
                        <h3 class="rBlack">pre-autorizado!</h3>
                        <div class="mt-4">
                          {/* <a href="https://app.percapita.mx/?from=Titulo" class="btn btn-primary btn-conoce-azul">
                            <h6 class="rBlack txt-azul-marino pt-1">Solicitar crédito</h6>
                          </a> */}
                          {/* <button disabled class="btn btn-primary btn-conoce-azul">
                              <h6 class="rBlack txt-azul-marino pt-1">Solicitar crédito</h6>
                            </button> */}
                          <a
                            href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                            class="btn btn-primary btn-conoce-azul"
                          >
                            <h6 class="rBlack txt-azul-marino pt-1">
                              Solicitar
                            </h6>
                          </a>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="bg-pabs-phone pb-5">
          <div class="container">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-5">
                <div class="mx-2">
                  <div class="bg-cuadro-azul">
                    <div class="mx-3 py-5">
                      <div class="mb-3">
                        <h3 class="rBlack display-4">Tengo título PABS</h3>
                      </div>
                      <div class="text-center my-5">
                        <img
                          src={logo_pabs}
                          alt="PROGRAMA DE APOYO AL BENEFICIO SOCIAL"
                          class="img-fluid logo-pabs"
                        />
                      </div>
                      <div class="">
                        <h3 class="rLight display-5">
                          ¡Ya tienes tu <b class="rBlack txt-azul">crédito</b>
                        </h3>
                        <h3 class="rBlack display-5">pre-autorizado!</h3>
                        <div class="mt-4">
                          {/* <a href="https://app.percapita.mx/?from=Titulo" class="btn btn-primary btn-conoce-azul">
                            <h6 class="rBlack txt-azul-marino pt-1">Solicitar crédito</h6>
                          </a> */}
                          {/* <button disabled class="btn btn-primary btn-conoce-azul">
                              <h6 class="rBlack txt-azul-marino pt-1">Solicitar crédito</h6>
                            </button> */}
                          <a
                            href="https://app.percapita.mx/Titulo?utm_source=percapita.mx&utm_medium=search&utm_campaign=none"
                            class="btn btn-primary btn-conoce-azul"
                          >
                            <h6 class="rBlack txt-azul-marino pt-1">
                              Solicitar
                            </h6>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Carrousel */}
      <div class="d-none d-sm-none d-md-block" ref={ref6}>
        <div class="bg-reviews py-5">
          <div class="container">
            <div class="row">
              <div class="text-center mb-5 display-6">
                <motion.div animate={animation19}>
                  <h3 class="rLight mb-0">Más de 1,500 personas</h3>
                </motion.div>

                <motion.div animate={animation20}>
                  <h1 class="rBlack my-0 display-5">han adquirido su</h1>
                </motion.div>

                <motion.div animate={animation21}>
                  <h1 class="rBlack txt-azul mt-0 display-4">
                    libertad económica
                  </h1>
                </motion.div>
              </div>
              <motion.div animate={animation22} class="glider-contain">
                <div class="glider">
                  <div>
                    <div class="card-review">
                      <div class="container">
                        <div class="row">
                          <div class="text-end">
                            <img
                              src={icono_percapita}
                              alt="PERCAPITA"
                              class="img-fluid icono-per-review"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 py-3">
                            <img
                              src={ana_luisa}
                              alt="PERCAPITA"
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div class="mb-3">
                              <h5 class="rExtraBold txt-azul-marino mb-0">
                                Ana Luisa
                              </h5>
                              <h5 class="rExtraBold txt-azul-marino mt-0">
                                Rodríguez
                              </h5>
                              <h5 class="rSemiBold txt-azul">
                                Préstamo de $5,000
                              </h5>
                            </div>
                            <div class="oRegular txt-gris">
                              <p>
                                Gracias al préstamo de $5,000 que adquirí con
                                Percapita pude regalarle un viaje a la playa a
                                toda mi familia, estuvimos muy contentos.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="card-review">
                      <div class="container">
                        <div class="row">
                          <div class="text-end">
                            <img
                              src={icono_percapita}
                              alt="PERCAPITA"
                              class="img-fluid icono-per-review"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 py-3">
                            <img
                              src={angelica_luis}
                              alt="PERCAPITA"
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div class="mb-3">
                              <h5 class="rExtraBold txt-azul-marino mb-0">
                                Angélica Luis
                              </h5>
                              <h5 class="rExtraBold txt-azul-marino mt-0">
                                De Velasco
                              </h5>
                              <h5 class="rSemiBold txt-azul">
                                Préstamo de $10,000
                              </h5>
                            </div>
                            <div class="oRegular txt-gris">
                              <p>
                              Gracias al préstamo de $10,000.00 que me dio Percapita pude invertir en mi pequeño negocio de comida, para generar más ingresos.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <div class="card-review">
                      <div class="container">
                        <div class="row">
                          <div class="text-end">
                            <img
                              src={icono_percapita}
                              alt="PERCAPITA"
                              class="img-fluid icono-per-review"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 py-3">
                            <img
                              src={ana_luisa}
                              alt="PERCAPITA"
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div class="mb-3">
                              <h5 class="rExtraBold txt-azul-marino mb-0">
                                Ana Luisa
                              </h5>
                              <h5 class="rExtraBold txt-azul-marino mt-0">
                                Rodríguez
                              </h5>
                              <h5 class="rSemiBold txt-azul">
                                Préstamo de $5,000
                              </h5>
                            </div>
                            <div class="oRegular txt-gris">
                              <p>
                                Gracias al préstamo de $5,000 que adquirí con
                                Percapita pude regalarle un viaje a la playa a
                                toda mi familia, estuvimos muy contentos.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="card-review">
                      <div class="container">
                        <div class="row">
                          <div class="text-end">
                            <img
                              src={icono_percapita}
                              alt="PERCAPITA"
                              class="img-fluid icono-per-review"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-4 py-3">
                            <img
                              src={angelica_luis}
                              alt="PERCAPITA"
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-12 col-sm-12 col-md-12 col-lg-7">
                            <div class="mb-3">
                              <h5 class="rExtraBold txt-azul-marino mb-0">
                                Angélica Luis
                              </h5>
                              <h5 class="rExtraBold txt-azul-marino mt-0">
                                De Velasco
                              </h5>
                              <h5 class="rSemiBold txt-azul">
                                Préstamo de $10,000
                              </h5>
                            </div>
                            <div class="oRegular txt-gris">
                              <p>
                              Gracias al préstamo de $10,000.00 que me dio Percapita pude invertir en mi pequeño negocio de comida, para generar más ingresos.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <button aria-label="Previous" class="glider-prev">
                  <i class="fa-solid fa-chevron-left"></i>
                </button>
                <button aria-label="Next" class="glider-next">
                  <i class="fa-solid fa-chevron-right"></i>
                </button>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="bg-reviews py-5">
          <div class="container">
            <div class="row">
              <div class="text-center mb-5 display-6">
                <h3 class="rLight mb-0">Más de 1,500 personas</h3>
                <h1 class="rBlack my-0 display-5">han adquirido su</h1>
                <h1 class="rBlack txt-azul mt-0 display-4">
                  libertad económica
                </h1>
              </div>
              <section class="">
                <div class="col-12">
                  <div class="mx-3">
                    <div class="customer-feedback card-review">
                      <div class="container text-center">
                        <div class="row">
                          <div class="col-md-offset-3 col-md-6 col-sm-offset-2 col-sm-8">
                            <div class="owl-carousel feedback-slider">
                              <div class="feedback-slider-item ">
                                <div class="d-flex">
                                  <div class="">
                                    <img
                                      src={ana_luisa}
                                      alt="PERCAPITA"
                                      class="img-fluid customer-img"
                                    />
                                  </div>
                                  <div class="">
                                    <div class="text-end">
                                      <img
                                        src={icono_percapita}
                                        alt="PERCAPITA"
                                        class="img-fluid icono-per-review"
                                      />
                                    </div>
                                    <h5 class="rSemiBold txt-azul h2">
                                      Préstamo <br /> de $5,000
                                    </h5>
                                  </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-1">
                                  <div class="mx-2">
                                    <div class="my-3">
                                      <h5 class="rExtraBold txt-azul-marino mb-0 h3">
                                        Ana Luisa Rodríguez
                                      </h5>
                                    </div>
                                    <div class="oRegular txt-gris">
                                      <p class="h3">
                                        Gracias al préstamo de $5,000 que
                                        adquirí con Percapita pude regalarle un
                                        viaje a la playa a toda mi familia,
                                        estuvimos muy contentos.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="feedback-slider-item ">
                                <div class="d-flex">
                                  <div class="">
                                    <img
                                      src={ana_luisa}
                                      alt="PERCAPITA"
                                      class="img-fluid customer-img"
                                    />
                                  </div>
                                  <div class="">
                                    <div class="text-end">
                                      <img
                                        src={icono_percapita}
                                        alt="PERCAPITA"
                                        class="img-fluid icono-per-review"
                                      />
                                    </div>
                                    <h5 class="rSemiBold txt-azul h2">
                                      Préstamo <br /> de 145,000
                                    </h5>
                                  </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-1">
                                  <div class="mx-2">
                                    <div class="my-3">
                                      <h5 class="rExtraBold txt-azul-marino mb-0 h3">
                                        Anagélica Luis De Velasco
                                      </h5>
                                    </div>
                                    <div class="oRegular txt-gris">
                                      <p class="h3">
                                        Gracias al préstamo de $14,000 que
                                        adquirí con Percapita pude regalarle un
                                        viaje a la playa a toda mi familia,
                                        estuvimos muy contentos.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="feedback-slider-item ">
                                <div class="d-flex">
                                  <div class="">
                                    <img
                                      src={ana_luisa}
                                      alt="PERCAPITA"
                                      class="img-fluid customer-img"
                                    />
                                  </div>
                                  <div class="">
                                    <div class="text-end">
                                      <img
                                        src={icono_percapita}
                                        alt="PERCAPITA"
                                        class="img-fluid icono-per-review"
                                      />
                                    </div>
                                    <h5 class="rSemiBold txt-azul h2">
                                      Préstamo <br /> de $5,000
                                    </h5>
                                  </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-1">
                                  <div class="mx-2">
                                    <div class="my-3">
                                      <h5 class="rExtraBold txt-azul-marino mb-0 h3">
                                        Ana Luisa Rodríguez
                                      </h5>
                                    </div>
                                    <div class="oRegular txt-gris">
                                      <p class="h3">
                                        Gracias al préstamo de $5,000 que
                                        adquirí con Percapita pude regalarle un
                                        viaje a la playa a toda mi familia,
                                        estuvimos muy contentos.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/*  */}

      {/* <div class="d-none d-sm-none d-md-block">
        <div class="container-fluid bg-gris">
          <div class="row py-5">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-2"></div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                <div class="text-end mb-4">
                  <h1 class="rLight txt-gris h3">
                    Con
                    <img src={logo_gde} alt="PERCAPITA" class="img-fluid logo-gde" />
                  </h1>
                  <h3 class="rLight txt-azul h6">FINANCIAMIENTOS</h3>
                </div>
                <div class="txt-ayuda">
                  <div class="ms-5 mb-4">
                    <h1 class="rLight txt-azul2">Te ayudamos</h1>
                    <h1 class="rLight txt-azul2">a adquirir tus</h1>
                    <h1 class="rBlack txt-azul2">productos preferidos</h1>
                  </div>
                  <div class="ms-5 mb-4">
                    <h4 class="rMedium txt-azul2">A través de financiamientos</h4>
                    <h4 class="rMedium txt-azul2">personales.</h4>
                  </div>
                  <div class="ms-5">
                    <a href="#" class="btn btn-primary btn-solicitar">
                      <h6 class="pt-1 rBlack">Conoce más</h6>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                <img src={productos} alt="PERCAPITA" class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none">
        <div class="container-fluid bg-gris">
          <div class="row py-5">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-12 col-lg-2"></div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-4">
                <div class="text-end mb-4">
                  <img src={logo_gde} alt="PERCAPITA" class="img-fluid logo-gde" />

                  <h3 class="rLight txt-azul h6">FINANCIAMIENTOS</h3>
                </div>
                <div class="txt-ayuda text-center">
                  <div class="ms-5 mb-4">
                    <h1 class="rLight txt-azul2 h3">Te ayudamos a adquirir tus</h1>
                    <h1 class="rBlack txt-azul2 h3">productos preferidos</h1>
                  </div>
                  <div class="ms-5 mb-4">
                    <h4 class="rMedium txt-azul2 display-4">A través de </h4>
                    <h4 class="rMedium txt-azul2 display-4">financiamientos</h4>
                    <h4 class="rMedium txt-azul2 display-4">personales.</h4>
                  </div>
                  <div class="ms-5">
                    <a href="#" class="btn btn-primary btn-solicitar">
                      <h6 class="pt-1 rBlack">Conoce más</h6>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 col-md-12 col-lg-6 pt-3">
                <img src={productos} alt="PERCAPITA" class="img-fluid pt-5" />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Footer />
    </div>
  );
};
