import React,{useEffect, useState} from 'react'
import logo_balnco from '../../../img/logo_blanco.png';
import { Navbar } from '../../../components/front/Navbar';
import { Footer } from '../../../components/front/Footer';
import {API_URL} from '../../../utils/config'
import AlertMessage from "../../alert.component";
import { UserContext } from "./../../../context/UserProvider";
import { useHistory, useLocation } from "react-router-dom";
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

export const Login = () => {
    const query = useQuery();
    const [openMessage, setOpenMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success");
    const [isLoading, setIsLoading] = useState(false);
    const [shown, setShown] = React.useState(false);
    const { userMain, updateUser } = React.useContext(UserContext);
    const [user, setUser] = useState({
        user: "",
        phone: "",
        password: "",
      });
      const handleClose = () => {
        setOpenMessage(false);
      };
      const handleInputChange = (e) => {
        setUser({
          ...user,
          [e.target.name]: e.target.value,
        });
      };

      const userLogin = (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        if (user.phone === "") {
          setOpenMessage(true);
          setMessage(
            "El número de teléfono del usuario es requerido. Favor de verificar"
          );
          setSeverity("error");
          setIsLoading(false);
          return;
        }
    
        if (user.password === "") {
          setOpenMessage(true);
          setMessage("La contraseña del usuario es requerido. Favor de verificar");
          setSeverity("error");
          setIsLoading(false);
          return;
        }
    
        login(user.phone, user.password)
          .then((res) => res.json())
          .then(async (data) => {
            if (data.error) {
              localStorage.clear();
              setOpenMessage(true);
              const statusCode = data.error?.status || data.error.statusCode;
              setMessage(
                statusCode === 401
                  ? "Telefono o contraseña invalidos"
                  : data.error.message
              );
              setSeverity("error");
              setIsLoading(false);
              return
            }  
            updateUser({
                ...userMain,
                nombre: data.fullName || data.fullName,
                userId: data.userId,
              });
               return  window.location.replace(`https://app.percapita.mx/login-cambaceo/${data.userId}/${data.fullName}/login`);
          });
      };

      const login = async(user, password) => {
            return fetch(`${API_URL}/login`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ user, password }),
            });
          };
    useEffect(() => {
        const checkFromRoute = async () => {
            const type = query.get("type");
            if (type === "logout") {
                updateUser({
                    ...userMain,
                    nombre: null,
                    userId: null,
                  });
            }
          };
          checkFromRoute();
          document.title  = "Percapita - Login Ventas"
    },[])

  
    return (
        <div class="blue-background-mess">
            <Navbar />
            <AlertMessage
                open={openMessage}
                message={message}
                handleClose={handleClose}
                severity={severity}
            />
            <form onSubmit={userLogin}>
                <div class="row">
                    <div class="col-2">
                    </div>
                    <div class="col-8">
                        <div class="d-flex justify-content-center mb-1">
                            <img src={logo_balnco} alt="PRADO'S" class="img-fluid p-2 img-client mt-5 mb-4" width="240" />
                        </div>
                        <div class="text-white text-center">
                            <h4 class="mt-2">Ingresa usuario y contraseña</h4>
                        </div>
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label white-tittle">Usuario</label>
                                    <input
                                        className="form-control"
                                        name="phone"
                                        placeholder=""
                                        aria-label="Default select example"
                                        onChange={handleInputChange}
                                        />
                                </div>
                                <div class="mb-3">
                                    <label for="exampleInputEmail1" class="form-label white-tittle">Contaseña</label>
                                    <input
                                    className="form-control"
                                    name="password"
                                    type={shown ? "text" : "password"}
                                    placeholder=""
                                    aria-label="Default select example"
                                    onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                            </div>
                        </div>
                        <div class="d-flex justify-content-center mt-2 mb-5">
                            <button  class="btn btn-continue-large mt-5" onclick={userLogin}>
                                Iniciar sesión
                            </button>
                        </div>

                    </div>
                    <div class="col-2">
                    </div>
                </div>
            </form>
            <Footer />
        </div>
    )
}
